import { useTranslation } from "react-i18next";
import BaseContainer from "../../components/Container";
import TabHeader from "../../components/TabHeader";
import { getTabItems } from "../../helpers/tabs";
import {Link, useParams} from "react-router-dom";
import BaseForm from "../../components/BaseForm";
import { useContext, useEffect, useState } from "react";
import { BaseContext } from "../../helpers/common";
import {Button, Col, Row, Table} from "react-bootstrap";
import { serverFetch, serverPost } from "../../helpers/server";
import SubmitButton from "../../components/SubmitButton";
import Notification from "../../components/Notification";
import Loader from "../../components/Loader";
import classnames from "classnames";
import AddOrEditLeagueAppsSiteModal from "../../components/modals/AddOrEditLeagueAppsSiteModal";
const _ = require('lodash');

function PartnerVenueSync() {
    const { userInfo, getApiUrl, settings } = useContext(BaseContext);
    const { t } = useTranslation("common");
    let { facilityLink } = useParams();
    const [showAddSiteModal, setShowAddSiteModal] = useState(false);
    const [partnerSites, setPartnerSites] = useState([]);
    const [venues, setVenues] = useState([]);
    const [partnerVenues, setPartnerVenues] = useState([]);
    const [partnerSiteLocationsMap, setPartnerSiteLocationsMap] = useState({});
    const [initialFields, setInitialFields] = useState({});
    const [selectedSite, setSelectedSite] = useState(null);
    const [sitesLoading, setSitesLoading] = useState(true);
    const [partnersLoading, setPartnersLoading] = useState(true);
    const [venuesLoading, setVenuesLoading] = useState(true);
    const [errorFields, setErrorFields] = useState(null);

    const fetchVenuesMap = () => {
        if (!selectedSite) {
            setVenuesLoading(false);
            return;
        }
        setVenuesLoading(true);
        serverFetch(getApiUrl(`/partner/sites/${selectedSite.id}/venues`)).then((venuesRes) => {
            if (venuesRes) {
                let pVenues = [];
                _.each(venuesRes.venues, (v) => {
                    if (_.isEmpty(v.subLocations)) {
                        pVenues.push({ label: v.name, value: v.id })
                    }

                    _.each(v.subLocations, (sl, i) => {
                        pVenues.push({
                            label: `${v.name} -> ` + sl.name, value: sl.id === "0" ? `${v.id}.${i}` : `${v.id}.${sl.id}`
                        })
                    })
                })
                setPartnerVenues(pVenues);
            }
            setVenuesLoading(false);
        });
    }

    useEffect(() => {
        fetchVenuesMap();
        setErrorFields(null);
    }, [selectedSite])

    const fetchPartnerMapping = (skipCache = false) => {
        serverFetch(getApiUrl("/venues/partner_mappings"), { skipCache }).then((res) => {
            setInitialFields(prevMap => {
                const newMap = {...prevMap};

                res.siteMappings.forEach(site => {
                    site.mappings.forEach(mapping => {
                        newMap[`${site.siteId}-${mapping.venueId}`] = mapping.remoteId + (mapping.remoteSubId ? `.${mapping.remoteSubId}` : ``);
                    });
                });

                return newMap;
            });

            setPartnersLoading(false);
        });
    }
    
    const fetchData = (skipCache = false) => {
        serverFetch(getApiUrl("/venues"), { skipCache }).then((res) => {
            setVenues(res);
        })

        serverFetch(getApiUrl("/partner/sites"), { skipCache }).then((res) => {
            setPartnerSites(res);
            if (res) {
                if (res.length > 0) {
                    setSelectedSite(res[0]);

                    fetchVenuesMap(res);
                } else {
                    setPartnersLoading(false);
                }
            }
            setSitesLoading(false);
        });

        fetchPartnerMapping(skipCache);
        fetchVenuesMap();
    }
    
    useEffect(() => {
        setSitesLoading(true);
        setPartnersLoading(true);
        setVenuesLoading(true);
        fetchData(true);
    }, [facilityLink]);

    const onFieldChange = (target, value) => {
        if (target === "selectedSite") {
            setSelectedSite(partnerSites.find((site) => site.id.toString() === value.toString()));
        }
    }

    const onModalClose = (didUpdate) => {
        setShowAddSiteModal(false);
        if (didUpdate) {
            fetchData(true);
        }
    }

    const onError = async (response, errorMessage) => {
        if (errorMessage.error) {
            Notification.Show(errorMessage.error);
        }
    }

    const onSubmit = async (data) => {
        const mappingData = { siteMappings: [{
            partnerId: selectedSite.partnerId,
            siteId: selectedSite.id,
            mappings: [],
        }] }
        const keyMap = {};
        for (const [key, value] of Object.entries(data)) {
            const venueId = key.split("-")[1];
            if (!venueId) {
                continue;
            }
            const remoteInfo = value ? value.split(".") : [null];
            mappingData.siteMappings[0].mappings.push({
                venueId: venueId,
                remoteId: remoteInfo[0],
                remoteSubId: remoteInfo.length > 1 ? remoteInfo[1] : null,
            });
            if (value) {
                keyMap[value] = keyMap[value] || []
                keyMap[value].push(key)
            }
        }
        let hasErrors = false;
        let gErrors = {};
        _.each(keyMap, (keys, remoteId) => {
            if (remoteId && keys.length > 1) {
                hasErrors = true;
                _.each(keys, (k) => {
                    gErrors[k] = "A location can not be mapped to multiple venues"
                })
            }
        })
        if (!hasErrors) {
            setErrorFields(null);
            const res = await serverPost(getApiUrl("/venues/partner_mappings"), mappingData, {}, onError);
            if (res && !_.isEmpty(res)) {
                Notification.Show("Venues synced")
                fetchPartnerMapping(true);
            }
        } else {
            setErrorFields(gErrors);
        }
    }

    const partnerSiteOptions = partnerSites.map((site) => {
        return { value: site.id, label: site.name }
    });
    const partnerVenueOptions = _.map(partnerVenues, (pv) => {
        return pv
    })
    partnerVenueOptions.unshift({ value: null, label: "None" })
    console.log("The flags are " + sitesLoading + ", " + partnersLoading + ", " + venuesLoading);
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "venue-sort", { userInfo: userInfo, isPartnerFacility: settings.isPartnerFacility })}/>
            <div className="content-box">
                <div className="content-body">
                    <BaseForm onFieldChange={onFieldChange}>
                        <Row className="mb-2">
                            {
                                partnerSiteOptions.length > 0 ?
                                    <Col md="4">
                                        <BaseForm.Input type="select"
                                            label={t("partner.venuesync.connected_site")}
                                            name="selectedSite"
                                            options={partnerSiteOptions}
                                            showSearch={partnerSiteOptions.length > 5}
                                        />
                                    </Col>
                                : <div>
                                    <span>No LeagueApps sites connected. Feel free to <Link to={`/${facilityLink}/setting/partner_sites`}>add one here</Link>.</span>
                                </div>
                            }
                        </Row>
                    </BaseForm>
                    <Loader loading={sitesLoading || partnersLoading || venuesLoading}>
                    <BaseForm initialFormFields={initialFields} onSubmit={onSubmit} onFieldChange={onFieldChange} errorFields={errorFields}>
                        {
                        partnerSiteOptions.length > 0 && selectedSite &&
                        <>
                            <Row>
                                <Col md="9">
                                    <Table hover>
                                        <thead>
                                        <tr>
                                            <th>{t("common.venue")}</th>
                                            {
                                                selectedSite &&
                                                <th key={selectedSite.id}>{selectedSite.name}</th>
                                            }
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            venues.map((venue) => {
                                                return (
                                                    <tr key={venue.id}>
                                                        <td width="50%">{venue.name}</td>
                                                        <td>
                                                            <BaseForm.Input type="select"
                                                                            name={`${selectedSite.id}-${venue.id}`}
                                                                            options={partnerVenueOptions}
                                                                            showSearch={partnerVenueOptions.length > 6}
                                                                            disabled={Object.keys(initialFields).includes(`${selectedSite.id}-${venue.id}`)}
                                                            />
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <SubmitButton>{t("common.update")}</SubmitButton>
                                </Col>
                            </Row>
                        </>
                    }
                    </BaseForm>
                    </Loader>
                </div>
            </div>
            <AddOrEditLeagueAppsSiteModal
                show={showAddSiteModal}
                onClose={onModalClose}
            />
        </BaseContainer>
    );
}

export default PartnerVenueSync;
