import '../../App.scss';
import '../../css/modals.scss';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import BaseForm from '../BaseForm';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

function AddOrEditVenueCategoryModal(props) {
    const { t } = useTranslation('common');

    return (
        <BaseAddOrEditItemModal
            {...props}
            size="lg"
            itemLabel={"Venue Category"}
            itemBaseUrl={'/venue_categories'}
        >
            <Row>
                <BaseForm.Input colSpan={4} name={'categoryName'} label={"Category Name"} type='text' required />
                <BaseForm.Input colSpan={4} name={'categoryLink'} label={"Category Link"} type='text' required />
                <BaseForm.Input colSpan={4} name={'companyName'} label={"Company Name"} type='text'/>
                <BaseForm.Input colSpan={4} name={'address1'} label={"Street Address"} type='text'/>
                <BaseForm.Input colSpan={4} name={'address2'} label={"Street Address 2"} type='text'/>
                <BaseForm.Input colSpan={4} name={'city'} label={"City"} type='text'/>
                <BaseForm.Input colSpan={4} name={'state'} label={"Province/State"} type='text'/>
                <BaseForm.Input colSpan={4} name={'zipCode'} label={"Postal/Zip Code"} type='text'/>
                <BaseForm.Input colSpan={4} name={'phone'} label={"Phone"} type='text'/>
                <BaseForm.Input colSpan={4} name={'useAddress'} label={"Use Address"} type='check'/>
                <BaseForm.Input colSpan={4} name={'hideFromUser'} label={"Hide From User"} type='check'/>
                <BaseForm.Input colSpan={12} name={'userInstruction'} label={"Customer Instructions (How to Book)"} type='editor'/>
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditVenueCategoryModal;
