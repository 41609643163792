import React from 'react';
import { useEffect, useState, useRef, useContext, useMemo } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Notification from '../../components/Notification';
import BaseOverlayTrigger from '../../components/BaseOverlayTrigger';
import BaseForm from '../../components/BaseForm';
import BaseContainer from '../../components/Container';
import AddBookingLockerModal from '../../components/modals/AddBookingLockerModal';
import TabHeader from '../../components/TabHeader';
import { useSearchParams } from "react-router-dom";
import { serverFetch, serverPost } from '../../helpers/server';
import { BaseContext, hasAccess } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { Table, Button, Row, Col, ButtonToolbar, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ConfirmationButton from '../../components/ConfirmationButton';
import classnames from 'classnames';
import moment from 'moment';
const _ = require("lodash");

function AssignLockers() {
    const { getApiUrl, facilityLink, getFacilityName, userInfo } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [ searchParams ] = useSearchParams();
    const venueId = searchParams.get('venueid') || searchParams.get('rid');
    const date = searchParams.get('date') || moment().add(0, 'days').format("YYYY-MM-DD");
    const canUpdate = hasAccess("lockers", userInfo, "update");

    const [showAddBookingLockerModal, setShowAddBookingLockerModal] = useState(false);

    const shouldLock = useRef(false);
    const [startDate, setStartDate] = useState(date);
    const [autoAssign, setAutoAssign] = useState(false);
    const [venues, setVenues] = useState([]);
    const [loadingVenues, setLoadingVenues] = useState(true);
    const [bookingLockers, setBookingLockers] = useState([]);
    const [selectedVenueId, setSelectedVenueId] = useState(venueId);
    const [selectedVenue, setSelectedVenue] = useState(null);
    const [lockers, setLockers] = useState([]);
    const [refs, setRefs] = useState([]);

    useEffect(() => {
        document.title = "Locker Rooms - " + getFacilityName();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (!selectedVenueId) {
            return;
        }

        serverFetch(getApiUrl(`/booking_lockers/automate_assign?venueId=${selectedVenueId}`), { skipCache: true }).then((res) => {
            setAutoAssign(res.automated);
        })
    }, [selectedVenueId]);

    useEffect(() => {
        setSelectedVenue(_.find(venues, (v) => String(v.id) === String(selectedVenueId)));
    }, [selectedVenueId, venues])

    useEffect(() => {
        const newRefs = [];
        _.each(bookingLockers, (bl) => {
            const r = React.createRef();
            newRefs.push(r)
        });
        setRefs(newRefs);
    }, [bookingLockers]);

    const fetchData = (skipCache = false) => {
        serverFetch(getApiUrl('/venues'), { skipCache }).then((res) => {
            setVenues(res);
            setLoadingVenues(false)
        })

        serverFetch(getApiUrl('/lockers')).then((res) => {
            setLockers(res);
        })
    }

    useEffect(() => {
        onSearch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, loadingVenues, selectedVenueId]);

    const initialFields = useMemo(() => {
        return {
            startDate,
            venueId: selectedVenueId
        }
    }, [startDate, selectedVenueId])

    const onSearch = () => {
        if (_.isNil(startDate) || _.isNil(selectedVenueId)) {
            return;
        }
        if (loadingVenues) {
            return;
        }
        if (!moment(startDate).isValid()) {
            return;
        }

        serverFetch(getApiUrl(`/booking_lockers?date=${startDate}&venueId=${selectedVenueId}&includeBlocked=1`), { skipCache: true }).then((res) => {
            setBookingLockers(res);
        })
    }

    const onFieldChange = (name, value) => {
        if (name === "startDate") {
            setStartDate(value);
        } else if (name === "venueId") {
            setSelectedVenueId(value);
        }
    }

    const onModalClose = () => {
        setShowAddBookingLockerModal(false);
        onSearch();
    }

    const onAddNew = () => {
        setShowAddBookingLockerModal(true);
    }

    const onSave = (sLock) => {
        shouldLock.current = sLock;
        _.each(refs, (r, i) => {
            r.current.submitForm();
        });
    }

    const onChange = (event) => {
        setAutoAssign(event.target.checked);
        const data = {
            venueId: selectedVenueId,
            selected: event.target.checked
        }
        serverPost(getApiUrl('/booking_lockers/automate_assign'), data).then((res) => {
            if (res) {
                onSearch();
            } else {
                setAutoAssign(!event.target.checked);
            }
        })
    }

    const onAssign = () => {
        const data = {
            date: startDate,
            venueId: selectedVenueId
        }
        serverPost(getApiUrl('/booking_lockers/assign'), data).then((res) => {
            onSearch();
        })
    }

    const lockBookingLocker = (bookingLocker) => {
        serverPost(getApiUrl(`/booking_lockers/${bookingLocker.id}/lock`)).then((res) => {
            if (res) {
                Notification.Show(t('assign_lockers.successfully_locked'));
                onSearch();
            }
        })
    }

    const unlockBookingLocker = (bookingLocker) => {
        serverPost(getApiUrl(`/booking_lockers/${bookingLocker.id}/unlock`)).then((res) => {
            if (res) {
                Notification.Show(t('assign_lockers.successfully_unlocked'));
                onSearch();
            }
        })
    }

    const blockBookingLocker = (bookingLocker) => {
        serverPost(getApiUrl(`/booking_lockers/${bookingLocker.id}/block`)).then((res) => {
            if (res) {
                Notification.Show(t('assign_lockers.successfully_blocked'));
                onSearch();
            }
        })
    }

    const unblockBookingLocker = (bookingLocker) => {
        serverPost(getApiUrl(`/booking_lockers/${bookingLocker.id}/unblock`)).then((res) => {
            if (res) {
                Notification.Show(t('assign_lockers.successfully_unblocked'));
                onSearch();
            }
        })
    }

    const updateBookingLocker = (bookingLockerId, data) => {
        data.id = bookingLockerId;
        const batchData = {
            lock: shouldLock.current,
            bookingLockers: [data]
        }
        serverPost(getApiUrl(`/booking_lockers/batch_save`), batchData).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
                onSearch();
            }
        })
    }

    const venueOptions = _.map(venues, (venue, i) => { return { 'value': venue.id, 'label': venue.name } });
    const venueLockers = _.filter(lockers, (locker, i) => String(locker.venueId) === String(selectedVenueId));
    let lockerOptions = _.map(venueLockers, (locker, i) => { return { 'value': locker.id, 'label': locker.lockerName } });
    lockerOptions.unshift({ value: '0', label: t('assign_lockers.empty_select') })

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "locker-rooms")}/>
            <div className="content-box">
                <div className="content-body">
                {
                    !loadingVenues &&
                        <BaseForm initialFormFields={initialFields} onFieldChange={onFieldChange}>
                            <Row>
                            <BaseForm.Input colSpan="2" name="startDate" id="startDate" type="date" label={t('common.date')} rightContent={<i className="fa fa-calendar"/>} />
                            <BaseForm.Input colSpan="2" name="venueId" type="select" label={t('common.venue')} options={venueOptions} showSearch={true} />
                            <Col md="8" className="text-end">
                                <ButtonToolbar>
                                {
                                    canUpdate &&
                                        <>
                                            <Button variant="outline-primary" onClick={() => onAddNew()}><i className="fa fa-circle-plus"/> {t('common.add_new')}</Button>
                                            <Button variant="success" onClick={() => onSave(false)}>{t('common.save')}</Button>
                                            <Button variant="success" onClick={() => onSave(true)}>{t('assign_lockers.save_and_lock')}</Button>
                                        </>
                                }
                                    <BaseOverlayTrigger placement="bottom" content={t('assign_lockers.auto_assign_description')}>
                                        <Button variant="outline-primary">
                                            <Form.Check type="checkbox" id="auto-assign">
                                                <Form.Check.Label>{t('assign_lockers.auto_assign_daily')}</Form.Check.Label>
                                                <Form.Check.Input type="checkbox" checked={autoAssign} isValid onChange={onChange} disabled={!canUpdate}/>
                                            </Form.Check>
                                        </Button>
                                    </BaseOverlayTrigger>
                                {
                                    canUpdate &&
                                        <Button variant="primary" onClick={onAssign}><i className="fa fa-refresh"/> {t('assign_lockers.assign')}</Button>
                                }
                                </ButtonToolbar>
                            </Col>
                            </Row>
                        </BaseForm>
                }
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Table hover>
                        <thead>
                            <tr>
                                <th className="text-center" width="17%">Time</th>
                                <th className="text-center" width="17%">Group 1</th>
                                <th className="text-center" width="17%">Room</th>
                                <th className="text-center" width="17%">Group 2</th>
                                <th className="text-center" width="17%">Room</th>
                                <th width="15%"></th>
                            </tr>
                        </thead>
                    </Table>
                    {
                        _.map(bookingLockers, (bookingLocker, i) =>
                            <BaseForm key={i} initialFormFields={bookingLocker} onSubmit={(data) => updateBookingLocker(bookingLocker.id, data)} ref={refs[i]}>
                            <Table hover className={classnames('locker-assign', bookingLocker.isBlocked && "de-highlighted")}>
                                <tbody>
                                <tr>
                                    <td className="text-center" width="17%">
                                        <strong>{ moment(bookingLocker.startTimeLocal).format("h:mma - ") + moment(bookingLocker.endTimeLocal).format("h:mma") }</strong>
                                    </td>
                                    <td className="text-center" width="17%">
                                        <BaseForm.Control2 name="groupName1" type="text" label="Group 1" hideLabel className="inline" required disabled={bookingLocker.isBlocked} readOnly={!canUpdate}/>
                                    </td>
                                    <td className="text-center" width="17%">
                                        <BaseForm.SingleSelect name="locker1Id" options={lockerOptions} showAll={false}
                                            showSearch={true} required disabled={bookingLocker.isBlocked}
                                            label="Locker 1" hideLabel readOnly={!canUpdate}/>
                                    </td>
                                    <td className="text-center" width="17%">
                                        <BaseForm.Control2 name="groupName2" type="text" label="Group 2" hideLabel className="inline" disabled={bookingLocker.isBlocked} readOnly={!canUpdate}/>
                                    </td>
                                    <td className="text-center" width="17%">
                                        <BaseForm.SingleSelect name="locker2Id" options={lockerOptions} showAll={false}
                                            showSearch={true} required disabled={bookingLocker.isBlocked}
                                            label="Locker 2" hideLabel readOnly={!canUpdate}/>
                                    </td>
                                    <td className="text-center" width="15%">
                                    {
                                        canUpdate &&
                                            <ButtonToolbar>
                                            {
                                                bookingLocker.isLocked ?
                                                    <ConfirmationButton variant="success" title={t('assign_lockers.unlock')} body={t('assign_lockers.unlock_body')} onConfirm={() => unlockBookingLocker(bookingLocker)}><i className="fa fa-lock"/></ConfirmationButton>
                                                : <ConfirmationButton variant="outline-primary" title={t('assign_lockers.lock')} body={t('assign_lockers.lock_body')} onConfirm={() => lockBookingLocker(bookingLocker)}><i className="fa fa-lock"/></ConfirmationButton>
                                            }
                                            {
                                                bookingLocker.isBlocked ?
                                                    <ConfirmationButton variant="primary" title={t('assign_lockers.unblock')}  body={t('assign_lockers.unblock_body')} onConfirm={() => unblockBookingLocker(bookingLocker)}><i className="fa fa-refresh"/></ConfirmationButton>
                                                : <ConfirmationButton variant="outline-primary" title={t('assign_lockers.block')}  body={t('assign_lockers.block_body')} onConfirm={() => blockBookingLocker(bookingLocker)}><i className="fa fa-remove"/></ConfirmationButton>
                                            }
                                            </ButtonToolbar>
                                    }
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                            </BaseForm>
                        )
                    }
                </div>
            </div>
            <AddBookingLockerModal show={showAddBookingLockerModal} date={startDate} venue={selectedVenue} onClose={onModalClose} />
        </BaseContainer>
    );
}

export default AssignLockers;
