import { useEffect, useState, useContext, createRef } from 'react';
import '../../App.scss';
import '../../css/forms.scss';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import DailyTimesPrimeTimesModal from '../../components/modals/DailyTimesPrimeTimesModal';
import CopyVenueSettingsModal from '../../components/modals/CopyVenueSettingsModal';
import { serverFetch, serverPatch, clearRequestCache } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { timeOptions, getOptionsFromArray, yesNoOptions } from '../../helpers/input';
import { useParams, useSearchParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Button, Row, Alert, Col } from 'react-bootstrap';
import Notification from '../../components/Notification';
import SingleSelectDropdown from '../../components/SingleSelectDropdown';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function Booking() {
    const { getApiUrl, getFacilityName } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const [venueDetails, setVenueDetails] = useState({});
    const [otherVenues, setOtherVenues] = useState([]);
    const [showDailyTimesModal, setShowDailyTimesModal] = useState(false);
    const [showPrimeTimesModal, setShowPrimeTimesModal] = useState(false);
    const [showCopyVenueSettingsModal, setShowCopyVenueSettingsModal] = useState(false);
    const [sourceVenueIdToCopyFrom, setSourceVenueIdToCopyFrom] = useState(null);
    const [loading, setLoading] = useState(true);
    const [ searchParams ] = useSearchParams();
    const venueId = searchParams.get('id');
    const { t } = useTranslation('common');
    const formRef = createRef();

    useEffect(() => {
        document.title = `${venueDetails.name} (Settings) - ${getFacilityName()}`;
    }, [venueDetails]);

    useEffect(() => {
        setLoading(true);
        fetchSettings();

        serverFetch(getApiUrl(`/venues`)).then((res) => {
            setOtherVenues(_.filter(res, (r) => String(r.id) != String(venueId)));
        });
    }, [venueId]);

    const fetchSettings = (skipCache=false) => {
        serverFetch(getApiUrl(`/venues/${venueId}`), { skipCache }).then((res) => {
            setVenueDetails(res);
            setLoading(false);
        });
    }

    const updateSettings = (data) => {
        serverPatch(getApiUrl(`/venues/${venueId}/settings`), data).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
                clearRequestCache();
            }
        });
    }

    const onShowDailyTimesModal = (event) => {
        event.preventDefault();
        setShowDailyTimesModal(true);
    }

    const onShowPrimeTimesModal = (event) => {
        event.preventDefault();
        setShowPrimeTimesModal(true);
    }

    const onModalClose = (didUpdate) => {
        setShowDailyTimesModal(false);
        setShowPrimeTimesModal(false);
        setShowCopyVenueSettingsModal(false);
        if (didUpdate) {
            fetchSettings(true);
        }
    }

    const onCopySettingSelected = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if (formRef.current) {
            const formData = formRef.current.getFormData();
            if (!formData.copyFrom) {
                Notification.Show("Please select the venue to copy the settings from.")
            } else {
                setShowCopyVenueSettingsModal(true);
                setSourceVenueIdToCopyFrom(formData.copyFrom);
            }
        }
    }

    const calendarIntervalOptions = getOptionsFromArray([5, 10, 15, 20, 25, 30]);
    const bookingIntervalOptions = getOptionsFromArray([5, 10, 15, 20, 25, 30, 60]);
    const singleClickOptions = getOptionsFromArray(_.range(5, 125, 5));
    let paddingMarginTimeOptions = getOptionsFromArray([0, 5, 10, 15, 20, 25, 30, 60]);
    paddingMarginTimeOptions = [{value: "1", label: "None"}, ...paddingMarginTimeOptions]
    const paddingMarginOptions = [
        { value: 0, label: "None"},
        { value: 1, label: "Padding"},
        { value: 2, label: "Margin"},
    ];
    const marginOptions = getOptionsFromArray(_.range(0, 60, 5));
    const endingMinutesOptions = [
        { value: "0", label: t('common.no') },
        { value: "1", label: ":15 and :45" },
        { value: "2", label: ":00 and :30" },
        { value: "3", label: ":00 only" },
        { value: "4", label: ":30 only" },
    ];
    const bookingRequestOptions = [
        { value: "no", label: t('common.no') },
        { value: "yes", label: t('common.yes') },
        { value: "afterAdvance", label: "Only after advanced booking period" }
    ];

    const tabsParams = {
        searchParams: venueId && `id=${venueId}`,
        venueName: venueDetails.name
    };

    const otherVenueOptions = _.map(otherVenues, (vv) => {
        return {
            value: vv.id,
            label: vv.name
        }
    })
    otherVenueOptions.unshift({ value: null, label: "Select Venue" });

    // TODO(Aswin): Fix the webcal url
    const renderContent = () => {
        return (
            <BaseForm ref={formRef} initialFormFields={venueDetails} onSubmit={updateSettings}>
                <div className="content-box">
                    <div className="content-header">Calendar And Booking Times</div>
                    <div className="content-body">
                    {
                        !_.isEmpty(otherVenues) &&
                            <Row>
                                <BaseForm.Input
                                    colSpan={3} name={'copyFrom'} label={"Copy Settings From"} type={'select'} options={otherVenueOptions}
                                    rightContent={
                                        <Button variant="success" onClick={(event) => onCopySettingSelected(event)}>
                                            <i className="fa fa-save"/>
                                        </Button>
                                    }
                                    showSearch={otherVenues.length > 6}
                                />
                            </Row>
                    }
                        <Row>
                            <BaseForm.Input colSpan={3} name={'dayStart'} label={"Day Start"} type={'select'} options={timeOptions(5)} />
                            <BaseForm.Input colSpan={3} name={'dayEnd'} label={"Day End"} type={'select'} options={timeOptions(5)} />
                        </Row>
                        <Row>
                            <BaseForm.Input colSpan={3} name={'calendarInterval'} label={"Calendar Interval"} type={'select'} options={calendarIntervalOptions} rightContent={"min"} />
                            <BaseForm.Input colSpan={3} name={'slotMinutes'} label={"Booking Interval"} type={'select'} options={bookingIntervalOptions} rightContent={"min"} />
                            <BaseForm.Input colSpan={3} name={'labelInterval'} label={"Label Interval"} type={'select'} options={bookingIntervalOptions} rightContent={"min"} />
                            <BaseForm.Input colSpan={3} name={'singleClick'} label={"Single Click"} type={'select'} options={singleClickOptions} rightContent={"min"} />
                            <BaseForm.Input colSpan={3} name={'useDailyTimes'} label={<a href="#dailyTimes" onClick={(event) => onShowDailyTimesModal(event, true)}>Use Daily Times</a>} type={'check'} />
                            <BaseForm.Input colSpan={3} name={'usePrimeTimes'} label={<a href="#primeTimes" onClick={(event) => onShowPrimeTimesModal(event, true)}>Use Prime Times</a>} type={'check'} />
                        </Row>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-header">Booking Padding/Margin</div>
                    <div className="content-body">
                        <Row>
                            <BaseForm.Input colSpan={3} name={'isPadding'} label={"Padding/Margin"} type={'select'} options={paddingMarginOptions} />
                            <BaseForm.Input colSpan={3} name={'defaultPadding'} label={"Padding/Margin Time"} type={'select'} options={paddingMarginTimeOptions} rightContent={"min"} />
                            <BaseForm.Input colSpan={3} name={'paddingName'} label={"Padding/Margin Name"} type={'text'} />
                            <BaseForm.Input colSpan={3} name={'paddingUser'} label={"Apply to User Booking"} type={'check'} />
                        </Row>
                        <Alert variant="info">
                            <strong>Padding time</strong> is within the booking and <strong>Margin time</strong> is outside the booking. The time amount
                            will show at the bottom to indicate a buffer time. The visible padding is currently only to
                            scale for Calendar Intervals of 5, 15, or 30. Please contact us if making the switch from
                            the seperate margin option below.
                        </Alert>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-header">Booking Margin (Separate Booking)</div>
                    <div className="content-body">
                        <Row>
                            <BaseForm.Input colSpan={4} name={'bookingMargin'} label={"Margin"} type={'select'} options={marginOptions} rightContent={"min"} />
                            <BaseForm.Input colSpan={4} name={'marginGroup'} label={"Margin Group Name"} type={'text'} />
                            <BaseForm.Input colSpan={4} name={'marginColor'} label={"Margin Color"} type={'color'} />
                        </Row>
                        <Alert variant="info">
                            Margin time is outside the booking and will show after to indicate a buffer time.
                            The margin time acts like a seperate booking.
                        </Alert>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-header">Customer Booking Options</div>
                    <div className="content-body">
                        <Row>
                            <BaseForm.Input colSpan={3} name={'advanceBooking'} label={"Advance Booking"} type="number" rightContent={"Days"} min="0"/>
                            <Col md="4">
                                <BaseForm.InputGroup className="form-group">
                                    <BaseForm.Date name="advanceBookingDate" label="Advanced Booking Date" />
                                    <BaseForm.Check name="useAdvanceBookingDate" />
                                </BaseForm.InputGroup>
                            </Col>
                            <BaseForm.Input colSpan="5" name={'advanceBookingMessage'} label={"Advanced Booking Warning Message"} type={'textarea'}
                                placeholder={"Add custom message for when a customer is unable to book"} />
                        </Row>
                        <p>How many days in advance a user can book. Advanced date will override the days selection.</p>
                        <Row>
                            <BaseForm.Input colSpan={3} name={'noBookingWithin'} label={"No Booking Within"} type="number" min="0" rightContent={"Hours"} />
                            <BaseForm.Input colSpan={3} name={'noBookStart'} label={"Except Within (Start)"} type={'select'} options={timeOptions(5)}  />
                            <BaseForm.Input colSpan={3} name={'noBookEnd'} label={"Except Within (End)"} type={'select'} options={timeOptions(5)}  />
                            <BaseForm.Input colSpan={3} name={'noBookMessage'} label={"Custom Min/Max Warning Message"} type={'textarea'}
                                placeholder="Add custom message for when a customer is unable to book"/>
                        </Row>
                        <p>Hours from the current time when a users can book. Time range when hours setting will not apply. (I.e. Business Hours)</p>
                        <Row>
                            <BaseForm.Input colSpan={3} name={'minUserBooking'} label={"Min Booking Time"} type="number" min="0" rightContent={"Min"} />
                            <BaseForm.Input colSpan={3} name={'maxUserBooking'} label={"Max Booking Time"} type="number" min="0" rightContent={"Min"} />
                            <BaseForm.Input colSpan={3} name={'minBookingGap'} label={"Min Booking Gap"} type="number" min="0" rightContent={"Min"} />
                        </Row>
                        <p>Min and max time a user can book online. Min gap a user must leave between bookings.</p>
                        <Row>
                            <BaseForm.Input colSpan={3} name={'userBookInterval'} label={"User Booking Interval"} type="number" min="0" rightContent={"Min"} />
                            <BaseForm.Input colSpan={3} name={'specificEndMin'} label={"Require Specific Ending Minutes"} type={'select'} options={endingMinutesOptions} />
                        </Row>
                        <p>Booking interval forces users to make bookings only on the set interval. (I.e 60,120,180 etc. minutes)</p>
                        <span><strong>Customer booking options can be broken down into a few different options</strong></span><br/>
                        <span><strong>Booking Request</strong> - This allows customers to request a booking. The booking will only be confirmed (or denied) after an admin takes action.</span><br/>
                        <span><strong>Pay Later</strong> - This allows customers to create a booking without any payment information.</span><br/>
                        <span><strong>Credit Card Booking</strong> - Customers will be able to create a booking along with payment information. The booking will be only be confirmed once the payment is successful.</span><br/>
                        <p>Each of these options are generally used independent of each other.</p>
                        <Row>
                            <BaseForm.Input colSpan={3} name={'allowBookingRequest'} label={"Allow Booking Request"} type={'select'} options={bookingRequestOptions} showSearch={false} />
                            <BaseForm.Input colSpan={3} name={'allowOnlineBooking'} label={"Allow Pay Later Booking"} type={'select'} options={yesNoOptions(t)} showSearch={false} />
                            <BaseForm.Input colSpan={3} name={'allowCCBooking'} label={"Allow Credit Card Booking"} type={'check'} />
                        </Row>
                        <p>Online booking options for a user: Request, Book Online & credit card booking.</p>
                        <Row>
                            <BaseForm.Input colSpan={6} name={'userCustomMsg'} label={"Custom Booking Message"} type={'textarea'}
                                placeholder={"If empty, no message will show"} />
                        </Row>
                        <p>This message will show on the booking popup for customers. Provide additional information to customers about bookings on this venue.</p>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-header">Extra Options</div>
                    <div className="content-body">
                        <Row>
                            <BaseForm.Input colSpan={6} name={'hideUser'} label={"Hide Group Names From Public"} type={'check'} />
                            <BaseForm.Input colSpan={6} name={'hideEventName'} label={"Hide Event Name From Public"} type={'check'} />
                            <BaseForm.Input colSpan={6} name={'hideCalendar'} label={"Hide Public Calendar"} type={'check'} />
                            <BaseForm.Input colSpan={6} name={'hideEventType'} label={"Hide Event Type From Public"} type={'check'} />
                            <BaseForm.Input colSpan={6} name={'allowBookingColor'} label={"Allow Booking Colors"} type={'check'} />
                            <BaseForm.Input colSpan={6} name={'notifyCustomerDefault'} label={"Notify Customer When Booking"} type={'check'} />
                            <BaseForm.Input colSpan={6} name={'allowNotes'} label={"Allow Booking Notes"} type={'check'} />
                            <BaseForm.Input colSpan={6} name={'simpleUserBook'} label={"Enable Simple User Booking"} type={'check'} />
                            <BaseForm.Input colSpan={6} name={'restoreGroups'} label={"Auto-Load Groups"} type={'check'} />
                        </Row>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-body">
                        <SubmitButton variant="primary">Save</SubmitButton>
                        <Button variant="outline-primary" href={`webcal://api.rectimes.com/api/v1/facilities/${facilityLink}/feed/ical?venueUUID=${venueDetails.uuid}`}><i className="fa fa-feed"/> Calendar Feed</Button>
                    </div>
                </div>
            </BaseForm>
        );
    }
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "venue-settings", tabsParams)}/>
            {
                !loading &&
                    renderContent()
            }
            <DailyTimesPrimeTimesModal type={showDailyTimesModal ? "dailyTimes": (showPrimeTimesModal ? "primeTimes" : null)} show={showDailyTimesModal || showPrimeTimesModal} onClose={onModalClose} settings={venueDetails} />
            <CopyVenueSettingsModal show={showCopyVenueSettingsModal} onClose={onModalClose} targetVenueId={venueId} sourceVenueId={sourceVenueIdToCopyFrom} />
        </BaseContainer>
    );
}

export default Booking;
