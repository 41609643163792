import { useEffect, useState, useContext } from 'react';
import '../App.scss';
import '../js/forms.js';
import $ from 'jquery';
import LoginPopup from './LoginPopup';
import QuickView from './QuickView';
import ContactFormModal from './modals/ContactFormModal';
import SimpleModal from './modals/SimpleModal';
import AddOrEditBookingModal from './modals/AddOrEditBookingModal';
import { getUrl, serverFetch, serverPost, subscribeToEvent } from '../helpers/server';
import { getUserInfo, getCompanyInfo, clearUserInfo, setCategory, getCategory } from '../helpers/storage'
import { isPartialOrFullAdmin, BaseContext, getFormattedImageLink, getFormattedName, hasAccess } from '../helpers/common'
import { useParams, useNavigate, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import CancelBookingModal from "./modals/CancelBookingModal";
const _ = require("lodash");

const languageMap = {
    'en': 'English',
    'fr': 'Français',
    'es': 'Español'
};

function Header(props) {
    const { userInfo, isLoggedIn, getApiUrl, facilityLink, settings } = useContext(BaseContext);
    const navigate = useNavigate();
    const { t, i18n } = useTranslation('common');

    const [showHowToBookModal, setShowHowToBookModal] = useState(false);
    const [showContactFormModal, setShowContactFormModal] = useState(false);
    const [showQuickView, setShowQuickView] = useState(false);
    const [showEditBookingModal, setShowEditBookingModal] = useState(false);
    const [showCancelBookingModal, setShowCancelBookingModal] = useState(false);
    const [bookingToEdit, setBookingToEdit] = useState(null);

    const [counts, setCounts] = useState({});
    const [categories, setCategories] = useState([]);
    const showLanguageSelection = facilityLink === "bmo";
    const currentCategoryId = getCategory(facilityLink);
    const currentCategory = _.find(categories, (c) => String(c.id) === String(currentCategoryId));

    const spoofToken = localStorage.getItem("spoof_auth_token");

    useEffect(() => {
        fetchData();

        subscribeToEvent("settings", (data) => { fetchData(true) });
        subscribeToEvent("bookings", (data) => { fetchTopCounts(true) });
    }, [facilityLink]);

    useEffect(() => {
        fetchTopCounts();
    }, [isLoggedIn, settings]);

    const fetchTopCounts = (skipCache=false) => {
        if (isLoggedIn && hasAccess("booking", userInfo, "update") && settings.topCount) {
            serverFetch(getApiUrl('/recent_booking_count'), { skipCache: skipCache }).then((res) => {
                if (res) {
                    setCounts(res);
                }
            })
        }
    }

    const fetchData = (skipCache=false) => {
        serverFetch(getApiUrl('/venue_categories'), { skipCache }).then((res) => {
            setCategories(res)
        })
    }

    const logout = (event) => {
        event.preventDefault();
        serverPost(getApiUrl('/logout'), {}).then((_) => {
            clearUserInfo();
            window.location = "/" + facilityLink;
        })
    }

    const endSpoof = () => {
        localStorage.setItem("auth_token", localStorage.getItem("spoof_auth_token"));
        localStorage.removeItem("spoof_auth_token");
        window.location.reload();
    }

    const changeLanguage = (event, language) => {
        event.preventDefault();
        i18n.changeLanguage(language);
        localStorage.setItem("language", language);
    }

    const toggleQuickView = (event) => {
        event.preventDefault();
        event.stopPropagation();
        if ($('#quickview').hasClass("show")) {
            // It is showing. Hide it.
            $('#quickview').removeClass('show');
            setShowQuickView(false);
        } else {
            // It is not showing. Show it.
            $('#quickview').addClass('show');
            setShowQuickView(true)
        }
    }

    const hideQuickView = () => {
        if ($('#quickview').hasClass("show")) {
            // It is showing. Hide it.
            $('#quickview').removeClass('show');
            setShowQuickView(false);
        }
    }

    const editBooking = (booking) => {
        setBookingToEdit({ ...booking, extendedProps: { ...booking }});
        setShowEditBookingModal(true);
    }

    const cancelBooking = (booking) => {
        setBookingToEdit({...booking, extendedProps: {...booking}});
        setShowCancelBookingModal(true);
    }

    const onModalClose = (didUpdate) => {
        setShowEditBookingModal(false);
        setShowCancelBookingModal(false);
    }

    const toggleLeftNav = (event) => {
        event.preventDefault();
        if ($('#left-nav').hasClass("show")) {
            // It is showing. Hide it.
            $('#left-nav').removeClass('show');
        } else {
            // It is not showing. Show it.
            $('#left-nav').addClass('show');
        }
    }

    const onCategoryClicked = (event, categoryId) => {
        event.preventDefault();
        setCategory(facilityLink, categoryId);
        localStorage.removeItem(facilityLink + "_calendar_venue");
        localStorage.removeItem(facilityLink + "_filter_cache_bookings");
        window.location.reload();
    }

    const facilities = [];
    let headerLogo = getFormattedImageLink(settings.headerLogo, "https://rectimes.app/uploads/logoheader/");
    let supportEmail = "support@rectimes.com"
    let supportWebsite = null
    let helpDeskUrl = "https://help.rectimes.net"
    if (settings.isPartnerFacility) {
        supportEmail = null
        helpDeskUrl = "https://support.leagueapps.com/"
        supportWebsite = "https://support.leagueapps.com/hc/en-us/categories/22515276561047-LeagueApps-Facilities"
    }

    return (
      <>
      <div className="header noprint">
        <div className="pull-left header-toolbar">
          <div className="header-item d-md-none">
            <div className="header-item-inner">
              <button type="button" className="btn btn-secondary btn-header" onClick={(event) => toggleLeftNav(event)}><i className="fa fa-bars"/></button>
            </div>
          </div>
          <div className="header-item d-none d-md-table-cell logo-left">
            <div className="header-item-inner header-logo">
              <Link to={"/" + facilityLink}><img src={headerLogo}/><span className="d-none d-md-inline">{settings.headerName}</span></Link>
            </div>
          </div>
          {
            isLoggedIn && userInfo.isSuperAdmin &&
              <div className="header-item d-none d-lg-table-cell">
                <div className="header-item-inner">
                  <div className="dropdown">
                    <button type="button" className="btn btn-secondary btn-header dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="-10,18">{t("header.manage")}</button>
                    <ul className="dropdown-menu">
                      <li><Link to={"/" + facilityLink + "/facility/list"}><i className="fa fa-building-o"></i> {t("header.facilities")}</Link></li>
                      <li><Link to={"/" + facilityLink + "/system/adminlist"}><i className="fa fa-users"></i> {t("header.admin_accounts")}</Link></li>
                      <li><Link to={"/" + facilityLink + "/system/accesslist?acc=super"}><i className="fa fa-globe"></i> {t("header.url_access")}</Link></li>
                    </ul>
                  </div>
                </div>
              </div>
          }
          {
            !_.isEmpty(categories) &&
              <div className="header-item">
                <div className="header-item-inner">
                  <div className="dropdown">
                    <button type="button" className="btn btn-secondary btn-header dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="-10,18">
                        <i className="fa fa-building-o d-md-none"/>
                        <span className="d-none d-md-inline"> {currentCategory ? currentCategory.categoryName : settings.allVenuesLabel}</span>
                    </button>
                    <ul className="dropdown-menu">
                      {
                        settings.showAllVenueOption &&
                          <li><Link to={"/" + facilityLink + "/cat/all"} onClick={(event) => onCategoryClicked(event, "all")}>{settings.allVenuesLabel}</Link></li>
                      }
                      {
                        _.map(categories, (c, i) =>
                          <li key={i}><Link to={"/" + facilityLink + "/cat/" + c.categoryLink} onClick={(event) => onCategoryClicked(event, c.id)}>{c.categoryName}</Link></li>
                        )
                      }
                    </ul>
                  </div>
                </div>
              </div>
          }
        </div>
        <div className="pull-center d-md-none header-toolbar">
          <div className="header-item">
            <div className="header-item-inner">
              <Link to={"/" + facilityLink}><img src={getFormattedImageLink(settings.headerLogo, "https://rectimes.app/uploads/logoinv/")}/><span className="d-none d-md-inline">{settings.headerName}</span></Link>
            </div>
          </div>
        </div>
        <div className="pull-right header-toolbar">
          {
            !_.isNil(spoofToken) &&
              <div className="header-item d-none d-md-table-cell">
                <div className="header-item-inner">
                  <Button variant="danger" onClick={endSpoof}>End Spoof</Button>
                </div>
              </div>
          }
          {
            showLanguageSelection &&
              <div className="header-item">
                <div className="header-item-inner">
                  <div className="dropdown">
                    <button type="button" className="btn btn-secondary btn-header dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="-5,18">{languageMap[i18n.language]}</button>
                    <ul className="dropdown-menu">
                      <li><a href="#en" onClick={(event) => changeLanguage(event, 'en')}>English</a></li>
                      <li><a href="#fr" onClick={(event) => changeLanguage(event, 'fr')}>Français</a></li>
                      <li><a href="#es" onClick={(event) => changeLanguage(event, 'es')}>Español</a></li>
                    </ul>
                  </div>
                </div>
              </div>
          }
          {
            isLoggedIn && isPartialOrFullAdmin(userInfo) > 0 && !settings.isPartnerFacility &&
              <div className="header-item d-none d-md-table-cell">
                <div className="header-item-inner">
                  <a className="btn-header" href="/referral"><span><i className="fa fa-gift"></i><span className="d-none d-lg-inline"> Get $300</span></span></a>
                </div>
              </div>
          }
          {
            isLoggedIn && isPartialOrFullAdmin(userInfo) > 0 && settings.topCount &&
              <div className="header-item d-none d-md-table-cell">
                <div className="header-item-inner">
                    <div className="btn btn-sm btn-danger bold" onClick={(event) => toggleQuickView(event)}><i className="fa fa-flag"></i> {counts.requestCount || 0} <i className="fa fa-book p-l-4"></i> {counts.bookingCount || 0}</div>
                </div>
              </div>
          }
          {
            isLoggedIn && isPartialOrFullAdmin(userInfo) > 0 && !settings.isPartnerFacility &&
              <div className="header-item">
                <div className="header-item-inner">
                  <a className="beamerTrigger btn-header" href="#"><span><i className="fa fa-bullhorn"></i><span className="d-none d-lg-inline"> {t('header.whats_new')}</span></span></a>
                </div>
              </div>
          }
          {
            (!isLoggedIn || !isPartialOrFullAdmin(userInfo)) &&
              <div className="header-item">
                <div className="header-item-inner">
                  <div className="dropdown">
                    <button type="button" className="btn btn-secondary btn-header dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="0,18"><i className="fa fa-question-circle"/><span className="d-none d-md-inline"> {t('header.support')}</span></button>
                    <ul className="dropdown-menu dropdown-menu-end">
                      <li><a href={"http://" + settings.website} target="_blank" rel="noreferrer"><i className="fa fa-globe"></i> {t('header.website')}</a> </li>
                      {
                        settings.phone &&
                          <li><a href={"tel:" + settings.phone}><i className="fa fa-phone"></i> {settings.phone}</a> </li>
                      }
                      <li><a href="#contactFormModal" onClick={(event) => { event.preventDefault(); setShowContactFormModal(true); }}><i className="fa fa-envelope"></i> {t('header.contact_form')}</a> </li>
                      <li><a href="#howToBookModal" onClick={(event) => { event.preventDefault(); setShowHowToBookModal(true); }}><i className="fa fa-question-circle"></i> {t('header.how_to_book')}</a> </li>
                    </ul>
                  </div>
                </div>
              </div>
          }
          {
            isLoggedIn &&
              <div className="header-item">
                <div className="header-item-inner">
                  <div className="dropdown">
                    <button type="button" className="btn btn-secondary btn-header dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-offset="5,18"><i className="fa fa-user"/><span className="d-none d-md-inline"> { getFormattedName(userInfo) }</span></button>
                    <ul className="dropdown-menu dropdown-menu-end">
                        {
                        (isPartialOrFullAdmin(userInfo) || userInfo.isSuperAdmin) &&
                          <>
                          <li><Link to={"/" + facilityLink + "/setting/adminlist"}><i className="fa fa-user"></i> {t('header.account_settings')}</Link></li>
                          {
                             !settings.isPartnerFacility &&
                                <li><a className="beamerTrigger" href="#"><i className="fa fa-bullhorn"></i> {t('header.whats_new')}</a> </li>
                          }
                          <li><a href={helpDeskUrl} target="_blank" rel="noreferrer"><i className="fa fa-info-circle"></i> {t('header.help_desk')}</a> </li>
                          {
                              supportEmail &&
                                <li><a href={`mailto:${supportEmail}`}><i className="fa fa-info-circle"></i> {supportEmail}</a> </li>
                          }
                          {
                              supportWebsite &&
                                <li><a href={supportWebsite}><i className="fa fa-info-circle"></i> Contact Us</a> </li>
                          }
                          </>
                      }
                      <li><a href="#logout" onClick={logout}><i className="fa fa-power-off"></i> {t('common.logout')}</a></li>
                      {
                        !_.isEmpty(facilities) &&
                            <li className="dropdown-header"><strong>Switch Facility</strong></li>
                      }
                      {
                        _.map(facilities, (facility, i) =>
                             <li key={i}><a href={`/${facility.link}`}><i className="fa fa-building"></i> {facility.name}</a></li>
                        )
                      }
                    </ul>
                  </div>
                </div>
              </div>
          }
          {
            !isLoggedIn &&
              <div className="header-item">
                <div className="header-item-inner">
                  <div className="dropdown" >
                    <button type="button" className="btn btn-secondary btn-header dropdown-toggle" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true" aria-expanded="false" data-bs-offset="13,18"><i className="fa fa-user"/><span className="d-none d-md-inline"> {t('common.login')}</span></button>
                    <LoginPopup />
                  </div>
                </div>
              </div>
          }
        </div>
      </div>
      <ContactFormModal show={showContactFormModal} onClose={setShowContactFormModal} headerName={settings.headerName} />
      <SimpleModal show={showHowToBookModal} onClose={setShowHowToBookModal} titleIcon="fa-question-circle" title={t('header.how_to_book')} content={settings.userInstruction} />
      <QuickView show={showQuickView} onHide={hideQuickView} onEditBooking={editBooking} onCancelBooking={cancelBooking} />
      <AddOrEditBookingModal booking={bookingToEdit} show={showEditBookingModal} onClose={onModalClose} />
      <CancelBookingModal booking={bookingToEdit} show={showCancelBookingModal} onClose={onModalClose} />
      </>
    );
}

export default Header;
