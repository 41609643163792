import '../../App.scss';
import '../../css/modals.scss';
import Notification from '../Notification';
import BaseForm from '../BaseForm';
import { useState, useEffect, useContext } from 'react';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row } from 'react-bootstrap';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function AddOrEditEventTypeModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [isEditing, setIsEditing] = useState(false);
    const venueName = props.venueName;
    const venueId= props.venueId;

    useEffect(() => {
         setIsEditing(!_.isNil(props.itemToEdit));
    }, [props.itemToEdit]);

    const processFields = (itemFields) => {
        itemFields.venueId = props.venueDetails.id;
        return itemFields;
    }

    return (
        <BaseAddOrEditItemModal
            {...props}
            itemLabel={"Event Type"}
            itemBaseUrl={'/event_types'}
            processAddFields={processFields}
            processUpdateFields={processFields}
        >
            {
                isEditing ?
                    <p>Edit event type for {props.venueDetails.name}</p>
                : <p>Add an event type for {props.venueDetails.name}</p>
            }
            <Row>
                <BaseForm.Input colSpan="12" name="eventType" label={t('forms.event_type')} type="text" placeholder="Event Type" required />
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditEventTypeModal;
