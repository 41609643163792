import '../App.scss';
import $ from "jquery";
import { useEffect, useState, useRef } from "react";
import { Button } from 'react-bootstrap';
import { useParams, useNavigate, Link } from 'react-router-dom';
import EditCustomerProfileModal from './modals/EditCustomerProfileModal';
import classnames from 'classnames';
const _ = require("lodash");

function TabHeader(props) {
    let { facilityLink } = useParams();
    const [showMore, setShowMore] = useState(false);
    const [shouldAutoMatch, setShouldAutoMatch] = useState(true);
    const [modals, setModals] = useState([]);
    const [showModal, setShowModal] = useState(null);
    const [modalParams, setModalParams] = useState({});
    const navigate = useNavigate();
    const lastWidth = useRef(0);
    const itemsCount = useRef(0);

    useEffect(() => {
        // Handler to call on window resize
        $( document ).ready(function() {
            function handleResize() {
                if (!$('#tabs').find(".dropdown-menu").is(":hidden")) {
                    $('#tabs').find(".dropdown-menu").removeClass('show');
                }
                // Set window width/height to state
                const numChildren = $('#tabs').children('.nav-link:not(.more)').length;
                const maxTotalWidth = $('#tabs').innerWidth() - 100;
                if (lastWidth.current === maxTotalWidth && itemsCount.current === numChildren) {
                    return;
                }
                lastWidth.current = maxTotalWidth;
                itemsCount.current = numChildren;
                let lastRight = 0;
                let itemId = 0;
                let startHiding = false;
                $('#tabs').children('.nav-link:not(.more)').each(function(i) {
                    const sisterId = $(this).attr('sister');
                    if (startHiding) {
                        $(this).addClass("hide");
                        $(sisterId).removeClass("hide");
                        !props.hideMore && setShowMore(true);
                        return
                    } else {
                        if ($(this).outerWidth(true) === 0) {
                            // The item is current hidden. Let's unhide so we can get its width.
                            $(this).removeClass("hide");
                            $(sisterId).addClass("hide");
                        }
                    }
                    if ($(this).position().left === 0) {
                        if (lastRight + $(this).outerWidth(true) < maxTotalWidth) {
                            $(this).removeClass("hide");
                            $(sisterId).addClass("hide");
                            lastRight = lastRight + $(this).outerWidth(true);
                            !props.hideMore && setShowMore(false);
                            itemId = itemId + 1;
                        } else {
                            $(this).addClass("hide");
                            $(sisterId).removeClass("hide");
                            !props.hideMore && setShowMore(true);
                            startHiding = true;
                        }
                    } else if (lastRight + $(this).outerWidth(true) < maxTotalWidth) {
                        lastRight = lastRight + $(this).outerWidth(true);
                        !props.hideMore && setShowMore(false);
                        itemId = itemId + 1;
                    } else {
                        $(this).addClass("hide");
                        $(sisterId).removeClass("hide");
                        !props.hideMore && setShowMore(true);
                        startHiding = true;
                        lastRight = lastRight + $(this).outerWidth(true);
                    }
                });
            }

            // Add event listener
            window.addEventListener("resize", handleResize);
            // Call handler right away so state gets updated with initial window size
            handleResize();
            // Remove event listener on cleanup
            return () => window.removeEventListener("resize", handleResize);
        });
    }, [props.items]);

    useEffect(() => {
        if (!_.isNil(props.shouldAutoMatch)) {
            setShouldAutoMatch(props.shouldAutoMatch);
        }
    }, [props.shouldAutoMatch]);

    useEffect(() => {
        const modals = _.map(_.filter(props.items, (item) => !_.isNil(item) && !_.isNil(item.modal)), (a) => a.modal);
        setModals(modals);
    }, [props.items]);

    const isItemActive = (item) => {
        if (item.active) {
            return item.active;
        } else if (shouldAutoMatch) {
            if (item.matchPathnameOnly) {
                return item.link === window.location.pathname;
            } else {
                return item.link === (window.location.pathname + window.location.search);
            }
        } else {
            return false;
        }
    }

    const onModalClose = (didUpdate) => {
        setShowModal(null);
        if (props.onModalClose) {
            props.onModalClose(didUpdate);
        }
    }

    const onClick = (event, item) => {
        if (!_.isNil(item.modal)) {
            event.preventDefault();
            setModalParams(item.params);
            setShowModal(item.modal);
        } else if (item.onClick) {
            event.preventDefault();
            item.onClick(item);
        }
    }

    return (
        <>
        <div className="tab-header noprint">
            <div className={classnames("nav-wrapper", "left", props.leftClassName)}>
                <NavItems items={props.items} shouldAutoMatch={shouldAutoMatch} onClick={onClick} showMore={showMore}/>
            </div>
            {
                !_.isEmpty(props.rightItems) &&
                    <div className={classnames("nav-wrapper", "right", props.rightClassName)}>
                        <NavItems items={props.rightItems} shouldAutoMatch={shouldAutoMatch} onClick={onClick} showMore={showMore}/>
                    </div>
            }
            {
                _.includes(modals, "EditCustomerProfileModal") && showModal === "EditCustomerProfileModal" &&
                    <EditCustomerProfileModal facilityLink={facilityLink} show={showModal === "EditCustomerProfileModal"} onClose={(didUpdate) => onModalClose(didUpdate)} {...modalParams} />
            }
        </div>
        </>
    );
}

function NavItems(props) {

    const isItemActive = (item) => {
        if (item.active) {
            return item.active;
        } else if (props.shouldAutoMatch) {
            if (item.matchPathnameOnly) {
                return item.link === window.location.pathname;
            } else {
                return item.link === (window.location.pathname + window.location.search);
            }
        } else {
            return false;
        }
    }

    return (
        <>
            <div className="nav" id="tabs">
            {
                _.map(props.items, (item, i) =>
                    item && (
                        isItemActive(item) ?
                            <Link key={i} className="nav-link active" to={item.link || "/"} sister={"#tabs" + i} onClick={(event) => props.onClick(event, item)}>
                            {
                                item.icon &&
                                    <>
                                    <i className={"fa fa-small " + item.icon}/>
                                    <span> </span>
                                    </>
                            }
                            {item.label}
                            </Link>
                        : <Link key={i} className={classnames("nav-link", item.disabled ? "disabled": "")} to={item.link || "/"} sister={"#tabs" + i} onClick={(event) => props.onClick(event, item)}>
                            {
                                item.icon &&
                                    <>
                                        <i className={"fa fa-small " + item.icon}/>
                                        <span> </span>
                                    </>
                            }
                            {item.label}
                            </Link>
                    )
                )
            }
                <div className="dropdown">
                    {
                        props.showMore &&
                            <Button variant="header" className={"nav-link more dropdown-toggle"} href="#" data-bs-toggle="dropdown" aria-haspopup="true" >More</Button>
                    }
                    <ul className="dropdown-menu dropdown-menu-end skinny">
                        <div className="nav-wrapper">
                        {
                            _.map(props.items, (item, i) =>
                                item && (
                                    isItemActive(item) ?
                                        <span key={i} className="nav-link active hide" id={"tabs" + i}>
                                        {
                                            item.icon &&
                                                <>
                                                <i className={"fa fa-small " + item.icon}/>
                                                <span> </span>
                                                </>
                                        }
                                        {item.label}
                                        </span>
                                    : <Link key={i} className={"nav-link hide"} to={item.link || "/"} id={"tabs" + i} onClick={(event) => props.onClick(event, item)}>
                                        {
                                            item.icon &&
                                                <>
                                                    <i className={"fa fa-small " + item.icon}/>
                                                    <span> </span>
                                                </>
                                        }
                                        {item.label}
                                        </Link>
                                )
                            )
                        }
                        </div>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default TabHeader;
