import $ from "jquery";
import 'jquery-ui/ui/widgets/sortable';
import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Loader from '../../components/Loader';
import BaseContainer from '../../components/Container';
import DeleteButton from '../../components/DeleteButton';
import AddOrEditEventTypeModal from '../../components/modals/AddOrEditEventTypeModal';
import TabHeader from '../../components/TabHeader';
import Notification from '../../components/Notification';
import BaseForm from '../../components/BaseForm';
import { serverFetch, serverPost, serverDelete, serverPatch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useSearchParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Table, Button, Row, Col, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function EventTypeList() {
    const { getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [ searchParams ] = useSearchParams();
    const venueId = searchParams.get('id');

    const [loading, setLoading] = useState(true);
    const [venues, setVenues] = useState([]);
    const [venueDetails, setVenueDetails] = useState({});
    const [items, setItems] = useState([]);
    const [copiedItems, setCopiedItems] = useState([]);
    const [initialSortedItems, setInitialSortedItems] = useState([]);
    const [itemToEdit, setItemToEdit] = useState(null);
    const [showAddEventTypeModal, setShowAddEventTypeModal] = useState(false);

    useEffect(() => {
        document.title = `${venueDetails.name} (Event Types) - ${getFacilityName()}`;
    }, [venueDetails]);

    useEffect(() => {
        serverFetch(getApiUrl(`/venues`), { skipAddingCategory: true }).then((res) => {
            setVenues(_.filter(res, (r) => String(r.id) !== venueId));
        });

        fetchData();
    }, [venueId]);

    useEffect(() => {
        if (loading) {
            return;
        }
        $(function() {
            $('#sortable-table').sortable({
                items:'tr',
                cursor: 'grabbing',
                stop: function(event, tbody) {
                    const sortedItems = $(this).sortable("toArray");
                    console.log("Comparing " + JSON.stringify(initialSortedItems) + ", " + JSON.stringify(sortedItems));
                    if (JSON.stringify(sortedItems) !== JSON.stringify(initialSortedItems)) {
                        updateEventTypeSort(sortedItems);
                    }
                },
                helper: function(e, tr) {
                    var $originals = tr.children();
                    var $helper = tr.clone();
                    $helper.children().each(function(index) {
                        // Set helper cell sizes to match the original sizes
                        $(this).width($originals.eq(index).width());
                    });
                    return $helper;
                }
            });
        });
    }, [initialSortedItems, loading]);

    const onModalClose = () => {
        fetchData(true);
        setShowAddEventTypeModal(false);
    }

    const fetchData = (skipCache = false) => {
        setLoading(true);
        serverFetch(getApiUrl(`/event_types`, { venueIds: [venueId] }), { skipCache, skipAddingCategory: true }).then((res) => {
            const venueEventTypes = _.filter(res, (r) => String(r.rinkId) === String(venueId));
            setCopiedItems(_.filter(res, (r) => _.includes(r.inheritedToVenueIds, parseInt(venueId))));
            setItems(venueEventTypes);
            if (!_.isEmpty(venueEventTypes)) {
                setInitialSortedItems(venueEventTypes.map(r => String(r.id)));
            } else {
                setInitialSortedItems([]);
            }
            setLoading(false);
        })

        serverFetch(getApiUrl(`/venues/${venueId}`), {skipCache}).then((res) => {
            setVenueDetails(res);
        });
    }

    const updateEventTypeSort = (orderedItems) => {
        const data = {
            venueId: venueId,
            ids: orderedItems
        };
        serverPost(getApiUrl('/event_types/sort'), data).then((res) => {
            fetchData(true);
        })
    }

    const addEventType = () => {
        setItemToEdit(null);
        setShowAddEventTypeModal(true);
    }

    const editEventType = (type) => {
        setItemToEdit(type);
        setShowAddEventTypeModal(true);
    }

    const deleteEventType = (type) => {
        serverDelete(getApiUrl(`/event_types/${type.id}`)).then((res) => {
            fetchData(true);
        })
    }

    const copyEventTypes = (data) => {
        serverPatch(getApiUrl(`/venues/${venueId}/settings`), data).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
            }
            fetchData(true);
        });
    }

    const venueOptions = _.map(venues, (v) => {
        return {
            label: v.name,
            value: v.id
        };
    });
    venueOptions.unshift({ value: null, label: "None" });
    const isCopyingEventTypes = !_.isNil(venueDetails.eventTypeCopy) && !_.isEmpty(String(venueDetails.eventTypeCopy));

    const tabsParams = {
        searchParams: venueId && `id=${venueId}`,
        venueName: venueDetails.name
    };
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "venue-settings", tabsParams)}/>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <Col md="9">
                        {
                            !isCopyingEventTypes ?
                                <Button variant="outline-primary" onClick={() => addEventType()}><i className="fa fa-plus-circle"></i> {t('setting.event_types.add')}</Button>
                            : <Alert>The event types for this venue is mirroring the event types from a different venue. To make edits, please visit that venue's event types page.</Alert>
                        }
                        </Col>
                        <Col md="3" className="text-end">
                            <BaseForm initialFormFields={venueDetails} onSubmit={copyEventTypes}>
                                <BaseForm.Input type="select" name="eventTypeCopy" label="Use Event Types from"
                                    showSearch={false} options={venueOptions}
                                    rightContent={
                                        <Button type="submit" variant="success"><i className="fa fa-save"/></Button>
                                    }/>
                            </BaseForm>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <div className="col-lg-9">
                            <Loader loading={loading}>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>{t('forms.event_type')}</th>
                                            <th>{t('common.sort_order')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody id={!isCopyingEventTypes ? "sortable-table": ""}>
                                        {
                                            _.map(items, (type, i) =>
                                                <tr key={i} id={type.id}>
                                                    <td>
                                                    {
                                                        !isCopyingEventTypes &&
                                                            <i className="fa fa-arrows-v" aria-hidden="true"></i>
                                                    }
                                                    </td>
                                                    <td>
                                                    {
                                                        !isCopyingEventTypes ?
                                                            <Button variant="alink" onClick={() => editEventType(type)}>{type.eventType}</Button>
                                                        : <span>{type.eventType}</span>
                                                    }
                                                    </td>
                                                    <td>{type.sort}</td>
                                                    <td>
                                                    {
                                                        !isCopyingEventTypes &&
                                                            <DeleteButton onDelete={() => deleteEventType(type)}></DeleteButton>
                                                    }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Loader>
                        </div>
                    </Row>
                </div>
            </div>
            {
                isCopyingEventTypes &&
                    <div className="content-box">
                        <div className="content-header">Event types copied from other venues</div>
                        <div className="content-body">
                            <Row>
                                <div className="col-lg-9">
                                    <Loader loading={loading}>
                                        <Table hover>
                                            <thead>
                                                <tr>
                                                    <th></th>
                                                    <th>{t('forms.event_type')}</th>
                                                    <th>{t('common.sort_order')}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    _.map(copiedItems, (type, i) =>
                                                        <tr key={i} id={type.id}>
                                                            <td></td>
                                                            <td><span>{type.eventType}</span></td>
                                                            <td>{type.sort}</td>
                                                            <td></td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                    </Loader>
                                </div>
                            </Row>
                        </div>
                    </div>
            }
            <AddOrEditEventTypeModal show={showAddEventTypeModal} onClose={onModalClose} itemToEdit={itemToEdit} venueDetails={venueDetails} />
        </BaseContainer>
    );
}

export default EventTypeList;
