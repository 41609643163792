import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/modals.scss';
import '../../css/table.scss';
import Loader from '../../components/Loader';
import BaseContainer from '../../components/Container';
import DeleteButton from '../../components/DeleteButton';
import AddOrEditAccountModal from '../../components/modals/AddOrEditAccountModal';
import TabHeader from '../../components/TabHeader';
import { serverFetch, serverDelete } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { getTabItems } from '../../helpers/tabs'
import { Table, Button, Row, Col, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function AccountList() {
    const { getApiUrl, facilityLink, getFacilityName } = useContext(BaseContext);
    const { t } = useTranslation('common');
    const [loading, setLoading] = useState(true);
    const [accounts, setAccounts] = useState([]);
    const [customerTypeToEdit, setCustomerTypeToEdit] = useState(null);
    const [showAddCustomerTypeModal, setShowAddCustomerTypeModal] = useState(false);

    useEffect(() => {
        document.title = "Accounts - " + getFacilityName();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const onModalClose = () => {
        fetchData(true);
        setShowAddCustomerTypeModal(false);
    }

    const fetchData = (skipCache = false) => {
        setLoading(true);
        serverFetch(getApiUrl('/accounts'), { skipCache }).then((res) => {
            setAccounts(res);
            setLoading(false);
        })
    }

    const addAccount = () => {
        setCustomerTypeToEdit(null);
        setShowAddCustomerTypeModal(true);
    }

    const editCustomerType = (item) => {
        setCustomerTypeToEdit(item);
        setShowAddCustomerTypeModal(true);
    }

    const deleteAccount = (item) => {
        const deleteAccountUrl = getApiUrl(`/accounts/${item.id}`);
        serverDelete(deleteAccountUrl, {}).then((res) => {
            fetchData(true);
        })
    }

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "accounts")}/>
            <div className="content-box">
                <div className="content-body">
                    <Alert variant="info">
                        <strong>Account Numbers:</strong> Use this page to add system wide account numbers. These numbers can be used to track different booking times and additional products as items get paid.
                    </Alert>
                    <Button variant="outline-primary" onClick={() => addAccount()}><i className="fa fa-plus-circle"></i> {t('setting.accounts.add')}</Button>
                </div>
            </div>
            <div className="content-box">
                <div className="content-body">
                    <Row>
                        <Col md="6">
                            <Loader loading={loading}>
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>{t('setting.accounts.account')}</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            _.map(accounts, (item, i) =>
                                                <tr key={i}>
                                                    <td><Button variant="alink" onClick={() => editCustomerType(item)}>{item.account}</Button></td>
                                                    <td><DeleteButton onDelete={() => deleteAccount(item)}></DeleteButton></td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Loader>
                        </Col>
                    </Row>
                </div>
            </div>
            <AddOrEditAccountModal show={showAddCustomerTypeModal} onClose={onModalClose} itemToEdit={customerTypeToEdit}/>
        </BaseContainer>
    );
}

export default AccountList;
