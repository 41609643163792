import '../../App.scss';
import '../../css/modals.scss';
import { useEffect, useState, useRef, useContext } from 'react';
import { serverPost } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import BaseForm from '../BaseForm';
import Select from 'react-select/async'
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { Row, Col, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import moment from 'moment';
const _ = require("lodash");

function AddOrEditCreditModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const selectRef = useRef(null);
    const [selectGroupId, setSelectedGroupId] = useState(null);
    const { t } = useTranslation('common');

    useEffect(() => {
        if (!_.isNil(props.group) && !_.isEmpty(props.group)) {
            setTimeout(() => {
                const value = {
                    id: props.group.id,
                    label: (
                        <>
                            <span className="title">{ props.group.groupName }</span><br/>
                            <span className="subtitle">{ props.group.user.email }</span>
                        </>
                    )
                }
                if (selectRef.current) {
                    selectRef.current.setValue([value]);
                }
                setSelectedGroupId(props.group.id);
            }, 2000);
        }
    }, [props.group, props.show])

    const fetchSubgroupsAutocomplete = async (query) => {
        return serverPost(getApiUrl('/user_groups/autocomplete'), { query: query }).then((res) => {
            _.each(res, r => {
                r.fullName = (r.firstName || "") + " " + (r.lastName || "");
                r.label = (
                    <>
                        <span className="title">{ r.groupName }</span><br/>
                        <span className="subtitle">{ r.user.email }</span>
                    </>
                );
            })
            return res;
        });
    }

    const processAddFields = (data) => {
        data.groupId = selectGroupId;
        data.createdDateLocal = moment(data.createdDate).utcOffset(0, true);
        return data;
    }

    const typeOptions = [
        { value: "credit", label: t("credit.add_credit_modal.credit_label") },
        { value: "debit", label: t("credit.add_credit_modal.debit_label") }
    ]

    return (
        <BaseAddOrEditItemModal
            {...props}
            processAddFields={processAddFields}
            itemLabel={"Credit"}
            itemBaseUrl={'/credits'}
        >
            <Row>
                <Col md="12">
                    <div className={classnames("form-group", (!_.isNil(props.group) && !_.isEmpty(props.group)) ? "disabled" : "")}>
                        <Form.Label>{t("credit.add_credit_modal.group")}</Form.Label>
                        <Select
                            ref={selectRef}
                            isDisabled={(!_.isNil(props.group) && !_.isEmpty(props.group))}
                            loadOptions={fetchSubgroupsAutocomplete}
                            className="select-container"
                            classNamePrefix="select2"
                            placeholder="Start typing to search for a group"
                            onChange={(value, meta) => setSelectedGroupId(value.id)}
                            noOptionsMessage={(inputValue) => { return "Start typing to search for a group" }}
                        />
                    </div>
                </Col>
                <BaseForm.Input colSpan="12" type="number" step="0.01" name="amount" label={t("credit.add_credit_modal.amount")} leftContent="$"
                            validations={{ required: true, min: 0.01 }} />
                <BaseForm.Input colSpan="12" name="type" label={t("credit.add_credit_modal.type")} type="select" options={typeOptions} showSearch={false} />
                <BaseForm.Input colSpan="12" name="createdDate" label={t("credit.add_credit_modal.entry_date")} type="date" required />
                <BaseForm.Input colSpan="12" name="note" label={t("credit.add_credit_modal.reference_date")} type="text" required />
            </Row>
        </BaseAddOrEditItemModal>
    );
}

export default AddOrEditCreditModal;
