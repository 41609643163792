import '../App.scss';
import '../css/modals.scss';
import $ from "jquery";
import 'jquery-ui/ui/widgets/datepicker';
import JoditEditor from "jodit-react";
import PropTypes from 'prop-types';
import React, { useContext, createContext, useState, useEffect, useRef, useMemo, useCallback, forwardRef, useImperativeHandle } from 'react';
import { getValueFromEvent } from '../helpers/input';
import {
    getColorOptions,
    validateEmail,
    validatePhone,
    escapeSelector,
    BaseContext,
    isInsideElement
} from '../helpers/common';
import { serverPost, serverPut } from '../helpers/server';
import { Form, InputGroup, Button } from 'react-bootstrap';
import { usePlacesWidget } from "react-google-autocomplete";
import { GithubPicker } from 'react-color';
import classnames from 'classnames';
import MultiSelectDropdown from './MultiSelectDropdown';
import SingleSelectDropdown from './SingleSelectDropdown';
import { useForm, Controller } from 'react-hook-form';
import {useTranslation} from "react-i18next";
const _ = require("lodash");

export const BaseFormContext = createContext();

const getValue = (formFields, name, defaultValue) => {
    const parts = name.split(".");
    let value = formFields;
    parts.forEach(part => {
        if (!_.isNil(value)) {
            value = value[part];
        }
    })
    if (!_.isNil(value)) {
        return value;
    } else {
        return defaultValue;
    }
}

const getValidationMessage = (key, params) => {
    const validationMessages = {
        required: `${params.label} is required`,
        validEmail: `Email address must be valid`,
        validPhone: `Phone number must be valid`,
        adminEmailCheck: `Email already exists. Please contact support to make this email an admin.`,
        userEmailCheck: `Email already exists.`,
        validEmails: `One or more of the email addresses is not valid`,
        minLength: `${params.label} must be at least ${params.value} characters long`,
        min: `${params.label} must be at least ${params.value}`,
        max: `${params.label} must be at most ${params.value}`,
        assigned: `${params.label} must be assigned`,
        isChecked: `${params.label} must be checked`,
        isMultipleOfTime: `${params.label} must be in ${params.value}mins intervals`
    }
    return validationMessages[key]
}
const customValidators = {
    validEmail: (shouldPass, value, isRequired) => validateEmail(value),
    validEmails: (shouldPass, value, isRequired) => {
        const emails = value.split(",")
        const invalidEmails = _.filter(emails, (e) => !validateEmail(e.trim()));
        return invalidEmails.length === 0;
    },
    validPhone: (shouldPass, value, isRequired) => {
        return validatePhone(value)
    },
    adminEmailCheck: async (params, value, isRequired) => {
        var shouldPass = true;
        var exceptionEmail = null;
        if (typeof(params) === 'object') {
            shouldPass = !_.isNil(params.shouldPass) ? params.shouldPass : true;
            exceptionEmail = params.except;
        }
        if (!shouldPass) {
            return true;
        } else if (exceptionEmail && value === exceptionEmail) {
            return true;
        }
        const data = {
            email: value,
            adminCheck: true,
        };
        const result = await serverPost(params.getApiUrl('/users/emailcheck'), data);
        return Promise.resolve(!result.exists);
    },
    userEmailCheck: async (params, value, isRequired) => {
        var shouldPass = true;
        var exceptionEmail = null;
        if (typeof(params) === 'object') {
            shouldPass = !_.isNil(params.shouldPass) ? params.shouldPass : true;
            exceptionEmail = params.except;
        }
        if (!shouldPass) {
            return true;
        } else if (exceptionEmail && value === exceptionEmail) {
            return true;
        }
        const data = {
            email: value
        };
        const result = await serverPost(params.getApiUrl('/users/emailcheck'), data);
        return Promise.resolve(!result.exists);
    },
    assigned: async (shouldPass, value, isRequired) => {
        return value && value !== 0;
    },
    isChecked: async (shouldPass, value, isRequired) => {
        return value === true;
    },
    isMultipleOfTime: async (expectedMultiple, value, isRequired) => {
        const minutes = parseInt(value.split(":")[1])
        return minutes % expectedMultiple === 0;
    }
};

const getGroupErrors = (errors, customErrorFields, props) => {
    const children = React.Children.toArray(props.children);
    const names = _.map(_.filter(children, (c) => c && c.props && c.props.name), (c) => c.props.name)
    const groupErrors = {};
    _.each(names, (name) => {
        let e = getValue(customErrorFields, name);
        if (!_.isNil(e)) {
            groupErrors[name] = e;
        } else {
            e = getValue(errors, name);
            if (!_.isNil(e)) {
                groupErrors[name] = e;
            }
        }
    })
    return groupErrors;
}

const hasCheckbox = (props) => {
    const children = React.Children.toArray(props.children);
    const checkField = _.find(children, c => c && c.type === BaseForm.Check);
    const hasCheck = checkField !== undefined;
    return hasCheck;
}

const hasRequiredFields = (props) => {
    const children = React.Children.toArray(props.children);
    const hasRequired = _.some(children, (c) => {
        if (_.isNil(c) || _.isNil(c.props)) {
            return false;
        }
        if (c.props && c.props.required) {
            return true;
        }
        const validation = (c.props && c.props.validations) || (c.props.rules) || {};
        if (_.isEmpty(validation)) {
            return false;
        } else {
            if (!validation.required) {
                return false;
            } else if (typeof(validation.required) === 'boolean') {
                return validation.required;
            } else {
                return validation.required.value;
            }
        }
    });
    return hasRequired;
}

export async function uploadToB2AndGetUrl(getApiUrl, type, file) {
    const fileData = {
        uploadReason: type,
        filename: file.name,
        contentType: file.type,
        contentLength: file.size
    }
    const signedUrlResponse = await serverPost(getApiUrl('/upload_file'), fileData);
    
    if (!signedUrlResponse) {
        return Promise.resolve(null)
    }

    const presignedUrl = signedUrlResponse.url;
    const host = signedUrlResponse.headers.host[0];
    fileData.filename = signedUrlResponse.filename;

    const putResponse = await serverPut(presignedUrl, file, {headers: signedUrlResponse.headers})
    if (putResponse) {
        const ackResponse = await serverPost(getApiUrl('/upload_file/ack'), fileData)
        return { url: ackResponse.url, filename: signedUrlResponse.filename }
    } else {
        return Promise.resolve(null);
    }
}

const BaseForm = forwardRef((props, ref)  => {
    const { getApiUrl } = useContext(BaseContext);
    useImperativeHandle(ref, () => ({
        submitForm() {
            handleSubmit(onSubmit)();
        },
        getFormData() {
            return getCurrentFormData();
        }
    }));

    const fieldNames = [];
    const fileFieldNames = {};
    const [readOnly, setReadOnly] = useState(false);
    const [formFields, setFormFields] = useState({});
    const [customErrorFields, setCustomErrorFields] = useState({});
    const [originalFormFields, setOriginalFormFields] = useState({});
    const [randomId, setRandomId] = useState(null);

    useEffect(() => {
        setRandomId(Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 5))
    }, []);

    useEffect(() => {
        setReadOnly(props.readOnly || false);
    }, [props.readOnly])

    useEffect(() => {
        if (!_.isEqual(props.initialFormFields, originalFormFields)) {
            const newFormFields = {};
            _.each(props.initialFormFields, (v, k) => {
                if (_.includes(_.keys(props.initialFormFields), k)) {
                    newFormFields[k] = getValue(props.initialFormFields || {}, k);
                } else {
                    newFormFields[k] = v;
                }
            })
            reset(newFormFields || {});
            setFormFields(props.initialFormFields || {});
        }
        setOriginalFormFields(props.initialFormFields || {});
    }, [props.initialFormFields]);

    useEffect(() => {
        setCustomErrorFields(props.errorFields);
    }, [props.errorFields]);

    const processValidations = (name, label, validations) => {
        const processedValidations = {};
        let isRequired = false;
        if (_.has(validations, 'required')) {
            isRequired = validations['required'];
        }
        _.forEach(validations, (value, key) => {
            const params = { name, label, value };
            if (_.has(customValidators, key)) {
                processedValidations['validate'] = processedValidations['validate'] || {}
                processedValidations['validate'][key] = async (inputValue) => {
                    const checkResult = await customValidators[key](value, inputValue, isRequired);
                    if (!checkResult) {
                        return getValidationMessage(key, {...params, inputValue})
                    } else {
                        return null;
                    }
                }
            } else if (typeof(value) === "object") {
                processedValidations[key] = value;
            } else {
                processedValidations[key] = {
                    value: value,
                    message: getValidationMessage(key, params)
                }
            }
        });
        if (!_.has(processedValidations, 'required')) {
            processedValidations["required"] = {
                value: false
            }
        }
        return processedValidations;
    }

    const updateFormFields = (key, value) => {
        setFormFields((prevFormFields) => {
            const newFormFields = {...prevFormFields};
            let parts = key.split(".");
            let ob = newFormFields;
            for (let i = 0; i < parts.length - 1; i++) {
                ob = ob[parts[i]] || {};
            }
            ob[parts[parts.length - 1]] = value;
            return newFormFields;
        });
        if (props.onFieldChange) {
            props.onFieldChange(key, value)
        }
    }

    const registerName = (name) => {
        if (!fieldNames.includes(name)) {
            fieldNames.push(name);
        }
    }

    const registerFileField = (name, type) => {
        if (!_.has(fileFieldNames, name)) {
            fileFieldNames[name] = type
        }
    }

    const getCurrentFormData = () => {
        const formValues = getValues();
        let data = {};
        _.forEach(fieldNames, (name) => {
            let value = getValue(formValues, name);
            if (_.isUndefined(value)) {
                value = null;
            }
            const nameParts = name.split(".");
            let d = data;
            _.each(_.range(nameParts.length-1), (i) => {
                d[nameParts[i]] = d[nameParts[i]] || {};
                d = d[nameParts[i]];
            })
            d[nameParts[nameParts.length-1]] = value;
        });
        return data;
    }

    const onSubmit = async () => {
        setTimeout(() => {
            var submitButton = $('#' + randomId).find(':submit');
            if (submitButton) {
                submitButton.addClass('loading');
                submitButton.prop('disabled', true);
            }
        }, 30);
        const formData = getCurrentFormData();
        await Promise.all(_.map(Object.keys(fileFieldNames), async (name) => {
            if (formData[name] && formData[name].size > 0) {
                const fileData = formData[name];
                const temp = await uploadToB2AndGetUrl(getApiUrl, fileFieldNames[name], formData[name]);
                if (!temp) {
                    return
                }
                fileData.filename = temp.filename;
                formData[name+"_extra"] = fileData;
                formData[name] = temp.url
            }
        }));
        await props.onSubmit(formData);
        setTimeout(() => {
            var submitButton = $('#' + randomId).find(':submit');
            if (submitButton) {
                submitButton.removeClass('loading');
                submitButton.prop('disabled', false);
            }
        }, 30);
    };

    let { register, handleSubmit, reset, formState: { errors }, control, getValues } = useForm({ reValidateMode: 'onChange' });
    if (!_.isEmpty(errors)) {
        console.log(errors);
    }

    return (
        <BaseFormContext.Provider value={{ initialFormFields:formFields, updateFormFields:updateFormFields, register,
                                            registerName, registerFileField, errors, control, getValues, getValue,
                                            reset, customErrorFields, getApiUrl, processValidations, readOnly }}>
            <Form id={randomId} onSubmit={handleSubmit(onSubmit)}>
                { props.children }
            </Form>
        </BaseFormContext.Provider>
    );
})

const Input = (props) => {
    return (
        <div className={classnames("col-md-" + props.colSpan, props.inputWrapperClassName)}>
        {
            props.type === "select" &&
                <BaseForm.SelectGroup id={props.id} name={props.name} required={props.required} options={props.options}
                    label={props.label} labelField={props.labelField} idField={props.idField} showAll={props.showAll}
                    leftContent={props.leftContent} rightContent={props.rightContent} showSearch={props.showSearch}
                    disabled={props.disabled} className={props.className} readOnly={props.readOnly} />
        }
        {
            props.type === "multi-select" &&
                <BaseForm.MultiSelectGroup id={props.id} name={props.name} required={props.required} options={props.options}
                    label={props.label} showAll={props.showAll} labelField={props.labelField} idField={props.idField}
                    leftContent={props.leftContent} rightContent={props.rightContent} showSearch={props.showSearch}
                    disabled={props.disabled} className={props.className} valueAsCommaSeparated={props.valueAsCommaSeparated}
                    defaultSelectAll={props.defaultSelectAll} />
        }
        {
            props.type === "check" &&
                <BaseForm.CheckGroup
                    id={props.id} name={props.name} required={props.required} validations={props.validations}
                    leftContent={props.leftContent} rightContent={props.rightContent} disabled={props.disabled}
                    formClassName={props.formClassName} label={props.label} validationLabel={props.validationLabel}/>
        }
        {
            props.type === "date" &&
                <BaseForm.DateGroup id={props.id} placeholder={props.placeholder} type={"text"} required={props.required}
                    validations={props.validations}  name={props.name} leftContent={props.leftContent}
                    rightContent={props.rightContent} disabled={props.disabled} label={props.label} />
        }
        {
            (props.type === "text" || props.type === "password" || props.type === "textarea" || props.type === "number" || props.type === "address") &&
                <BaseForm.ControlGroup id={props.id} placeholder={props.placeholder} type={props.type}
                    required={props.required} validations={props.validations} name={props.name} label={props.label}
                    leftContent={props.leftContent} rightContent={props.rightContent} className={props.className}
                    disabled={props.disabled} min={props.min} max={props.max} onBlur={props.onBlur} step={props.step}
                    readOnly={props.readOnly} hideLabel={props.hideLabel}/>
        }
        {
            props.type === "file" &&
                <BaseForm.FileGroup id={props.id} placeholder={props.placeholder} type={props.type} required={props.required}
                    validations={props.validations} name={props.name} label={props.label} rawFile={props.rawFile} accept={props.accept}
                    leftContent={props.leftContent} rightContent={props.rightContent} fileType={props.fileType}
                    disabled={props.disabled} hidePreview={props.hidePreview} />
        }
        {
            props.type === "color" &&
                <BaseForm.ColorGroup name={props.name} label={props.label} leftContent={props.leftContent}
                    hideLabel={props.hideLabel} className={props.className}
                    rightContent={props.rightContent} disabled={props.disabled} />
        }
        {
            props.type === "editor" &&
                <BaseForm.EditorGroup id={props.id}
                    className={props.className} name={props.name} label={props.label} required={props.required}
                    validations={props.validations} disabled={props.disabled} height={props.height}
                    hideLabel={props.hideLabel} placeholder={props.placeholder} tokens={props.tokens} />
        }
        </div>
    );
}
BaseForm.Input = Input;

const Group = (props) => {
    const { errors, customErrorFields } = useContext(BaseFormContext);
    const hasCheck = hasCheckbox(props);
    const groupErrors = getGroupErrors(errors, customErrorFields, props);
    const hasError = !_.isEmpty(groupErrors);
    const hasRequired = hasRequiredFields(props);

    return (
        <>
            <Form.Group {...props} className={classnames("form-group", props.className, hasCheck ? "inline": "", hasError ? "error": "", hasRequired ? "required": "")} >
                { props.children }
            </Form.Group>
            {
                hasError &&
                    _.map(groupErrors, (error, key) =>
                        <p key={key} className="form-error-message">{error.message}</p>
                    )
            }
        </>
    );
}
BaseForm.Group = Group;

const BaseInputGroup = (props) => {
    const { errors, customErrorFields, readOnly } = useContext(BaseFormContext);
    const groupErrors = getGroupErrors(errors, customErrorFields, props);
    const hasError = !_.isEmpty(groupErrors);
    const hasRequired = hasRequiredFields(props) || props.required;

    const children = React.Children.toArray(props.children);
    const names = _.map(_.filter(children, (c) => c && c.props && c.props.name), (c) => c.props.name)

    const pprops = {...props};
    delete pprops['leftContent'];
    delete pprops['rightContent'];
    return (
        <>
            <InputGroup {...pprops} className={classnames("form-group", props.className, hasError ? "error": "", hasRequired ? "required": "", props.disabled ? "disabled" : "")} >
                {
                    props.leftContent &&
                        <InputGroup.Text>{props.leftContent}</InputGroup.Text>
                }
                {
                    React.Children.map(children, child => {
                        if (React.isValidElement(child)) {
                            return React.cloneElement(child, { disabled: props.disabled || props.readOnly || readOnly });
                        }
                        return child;
                    })
                }
                {
                    props.rightContent &&
                        <InputGroup.Text>{props.rightContent}</InputGroup.Text>
                }
            </InputGroup>
            {
                hasError &&
                    _.map(groupErrors, (error, key) =>
                        <p key={key} className="form-error-message text-start d-inline">{error.message || error}</p>
                    )
            }
        </>
    );
}
BaseForm.InputGroup = BaseInputGroup;

const SingleSelect = (props) => {
    const { initialFormFields, updateFormFields, registerName, control, processValidations } = useContext(BaseFormContext);
    registerName(props.name);
    const validations = props.validations || {};
    if (props.required) {
        validations['required'] = true;
    }
    const idField = props.idField || "value";
    const value = getValue(initialFormFields, props.name) || (props.options.length > 0 ? props.options[0][idField] : null);
    const adjustedValue = (!_.isUndefined(value) && !_.isNull(value) && !_.isNil(value)) ? value : null;
    const showSearch = _.isNil(props.showSearch) ? true: props.showSearch;
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    const processedValidations = processValidations(props.name, props.label, validations);
    return (
        <Controller
            control={control}
            name={props.name}
            rules={processedValidations}
            defaultValue={adjustedValue}
            render={({ field }) => {
                return (
                    <div className="dropdown">
                        <SingleSelectDropdown items={props.options} selectedId={adjustedValue} showAll={props.showAll} name={props.name}
                            idField={idField} labelField={props.labelField} label={props.label} showSearch={showSearch}
                            className="form dropdown" hideLabel={!showLabel} readOnly={props.readOnly}
                            onSelect={(value) => {
                                field.onChange(value);
                                updateFormFields(props.name, value);
                            }}
                            disabled={props.disabled}/>
                    </div>
                );
            }}
        />

    );
}
const SelectGroup = (props) => {
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    return (
        <BaseForm.InputGroup className={classnames(showLabel ? "": "inline", props.className)}
            leftContent={props.leftContent} rightContent={props.rightContent} disabled={props.disabled}>
            <BaseForm.SingleSelect {...props} />
        </BaseForm.InputGroup>
    );
}
BaseForm.Select = SingleSelect;
BaseForm.SingleSelect = SingleSelect;
BaseForm.SelectGroup = SelectGroup;

const MultiSelect = (props) => {
    const { initialFormFields, updateFormFields, registerName, control, processValidations } = useContext(BaseFormContext);
    registerName(props.name);
    const value = getValue(initialFormFields, props.name);
    let adjustedValue = (!_.isUndefined(value) && !_.isNull(value) && !_.isNil(value)) ? value : (props.defaultSelectAll ? _.map(props.options, (o) => o[props.idField || "id"]).join(",") : "");
    let formattedValue = adjustedValue;
    if (props.valueAsCommaSeparated) {
        if (typeof(adjustedValue) === "string" && _.isEmpty(adjustedValue)) {
            formattedValue = [];
        } else {
            formattedValue = String(adjustedValue).split(",");
        }
    }
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    const processedValidations = processValidations(props.name, props.label, props.validations);
    return (
        <Controller
            control={control}
            name={props.name}
            rules={processedValidations}
            defaultValue={adjustedValue}
            render={({ field }) => {
                return (
                    <MultiSelectDropdown label={props.label} items={props.options}
                        onItemsChange={(value) => {
                            let avalue = value;
                            if (props.valueAsCommaSeparated) {
                                avalue = value.join(",");
                            }
                            field.onChange(avalue);
                            updateFormFields(props.name, avalue);
                        }}
                        selectedItems={formattedValue}
                        hideLabel={!showLabel}
                        idField={props.idField}
                        labelField={props.labelField}
                    />
                );
            }}
        />

    );
}
const MultiSelectGroup = (props) => {
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    return (
        <BaseForm.InputGroup className={classnames(showLabel ? "": "inline", props.className)}
            leftContent={props.leftContent} rightContent={props.rightContent} disabled={props.disabled}>
            <BaseForm.MultiSelect {...props} />
        </BaseForm.InputGroup>
    );
}
BaseForm.MultiSelect = MultiSelect;
BaseForm.MultiSelectGroup = MultiSelectGroup;

const SingleSelectFlat = (props) => {
    const { initialFormFields, updateFormFields, registerName, control, processValidations } = useContext(BaseFormContext);
    registerName(props.name);
    const options = [
        { value: "standard", label: "standard/hr" },
        { value: "custom", label: "custom/hr" },
        { value: "flat", label: "flat rate" },
    ]
    const value = getValue(initialFormFields, props.name);
    const adjustedValue = (!_.isUndefined(value) && !_.isNull(value) && !_.isNil(value)) ? value : options[0].value;
    const processedValidations = processValidations(props.name, props.label, props.validations);
    const onSelect = (event, field, value) => {
        event.preventDefault();
        field.onChange(value);
        updateFormFields(props.name, value);
    }
    return (
        <Controller
            control={control}
            name={props.name}
            rules={processedValidations}
            defaultValue={getValue(initialFormFields, props.name) || ""}
            render={({ field }) => {
                return (
                    <div className={classnames("single-select-flat", props.disabled ? "disabled" : "")}>
                    {
                        _.map(options, (op, i) =>
                            <div key={i} className={classnames(op.value === adjustedValue ? "selected": "")}
                                onClick={(event) => onSelect(event, field, op.value)}>
                                {op.label}
                            </div>
                        )
                    }
                    </div>
                );
            }}
        />

    );
}
const SingleSelectFlatGroup = (props) => {
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    return (
        <BaseForm.InputGroup className={classnames(showLabel ? "": "inline", props.className)}
            leftContent={props.leftContent} rightContent={props.rightContent} disabled={props.disabled}>
            <BaseForm.SingleSelect {...props} />
        </BaseForm.InputGroup>
    );
}
BaseForm.SingleSelectFlat = SingleSelectFlat;
BaseForm.SingleSelectFlatGroup = SingleSelectFlatGroup;

const Control = (props) => {
    const { initialFormFields, updateFormFields, registerName, control, processValidations } = useContext(BaseFormContext);
    const controlField = useRef(null);
    registerName(props.name);
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    const validations = props.validations || {};
    if (props.required) {
        validations['required'] = true;
    }
    const pprops = {...props};
    delete pprops['required'];
    delete pprops['leftContent'];
    delete pprops['rightContent'];
    delete pprops['changeYear'];
    delete pprops['changeMonth'];
    delete pprops['defaultDate'];
    delete pprops['minDate'];
    delete pprops['yearRange'];
    delete pprops['hideIfNoPrevNext'];
    delete pprops['hideLabel'];
    delete pprops['dateFormat'];
    if (props.type === "date") {
        pprops['type'] = "text";
        pprops['id'] = pprops['id'] || pprops['name'];
    }
    if (props.type === "textarea") {
        pprops['as'] = "textarea"
    }
    const { ref: addressRef } = usePlacesWidget({
        apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
        onPlaceSelected: (place) => {
            const address = place.formatted_address;
            if (controlField.current) {
                controlField.current.onChange(address);
            }
            updateFormFields(props.name, address);
        },
        options:{
            types: ["address"],
            libraries: ["places"]
        }
    });
    if (props.type === "address") {
        pprops['ref'] = addressRef;
        pprops['type'] = "text";
    }
    const processedValidations = processValidations(props.name, props.label, validations || {});
    return (
        <>
        {
            showLabel &&
                <Form.Label>{props.label}</Form.Label>
        }
        <Controller
            control={control}
            name={props.name}
            rules={processedValidations}
            defaultValue={getValue(initialFormFields, props.name, "")}
            render={({ field }) => {
                controlField.current = field;
                let defaultValue = getValue(initialFormFields, props.name, "");
                const fieldId = props.id || props.name;
                if (props.type === "date") {
                    const datepickerId = "datepicker." + fieldId;
                    const dateFormat = props.dateFormat || "yy-mm-dd";
                    $(document).click(function(event) {
                        var $target = $(event.target);
                        const x = event.pageX;
                        const y = event.pageY;
                        let isInside = false;
                        $(".ui-datepicker").each(function( index ) {
                            isInside = isInsideElement($(this), x, y) || isInside;
                        });
                        $('#' + escapeSelector(fieldId)).each(function( index ) {
                            isInside = isInsideElement($(this), x, y) || isInside;
                        });
                        if(!isInside && $('#' + escapeSelector(datepickerId)).hasClass("hasDatepicker")) {
                            $('#' + escapeSelector(datepickerId)).datepicker( "destroy" );
                        } else if($target.closest('.ui-datepicker').length === 1 &&
                            $('#' + escapeSelector(datepickerId)).hasClass("hasDatepicker")) {
                            event.preventDefault();
                            event.stopPropagation();
                        }
                    });
                    $( function() {
                        $('#' + escapeSelector(fieldId)).focus((event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            $('#' + escapeSelector(datepickerId)).datepicker( "destroy" );
                            $('#' + escapeSelector(datepickerId)).datepicker({
                                defaultDate: !_.isNil(props.defaultDate) ? props.defaultDate : 0,
                                minDate: props.minDate,
                                changeYear: !_.isNil(props.changeYear) ? props.changeYear : true,
                                changeMonth: !_.isNil(props.changeMonth) ? props.changeMonth : true,
                                yearRange: !_.isNil(props.yearRange) ? props.yearRange : "c-100:c+10",
                                dateFormat: dateFormat,
                                hideIfNoPrevNext: !_.isNil(props.hideIfNoPrevNext) ? props.hideIfNoPrevNext : true,
                                onSelect: (value) => {
                                    $('#' + escapeSelector(datepickerId)).datepicker( "destroy" );
                                    field.onChange(value);
                                    updateFormFields(props.name, value);
                                }
                            });
                            $('#' + escapeSelector(datepickerId)).datepicker( "setDate", getValue(initialFormFields, props.name, ""));
                        });
                    });
                }
                return (
                    <>
                        <Form.Control {...pprops}
                            disabled={props.disabled}
                            value={defaultValue}
                            onChange={(event) => {
                                field.onChange(getValueFromEvent(event));
                                updateFormFields(props.name, getValueFromEvent(event));
                            }}>
                            { props.children }
                        </Form.Control>
                        {
                            props.type === "date" &&
                                <span id={'datepicker.' + fieldId} style={{position:"absolute", background:"red", zIndex:100}}>
                                </span>
                        }
                    </>
                );
            }}
        />
        </>
    );
}
const Control2 = (props) => {
    const { initialFormFields, updateFormFields, registerName, control, processValidations } = useContext(BaseFormContext);
    registerName(props.name);
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    const validations = props.validations || {};
    if (props.required) {
        validations['required'] = true;
    }
    const pprops = {...props};
    delete pprops['required'];
    if (props.type === "date") {
        pprops['type'] = "text"
    }
    if (props.type === "textarea") {
        pprops['as'] = "textarea"
    }
    delete pprops['leftContent'];
    delete pprops['rightContent'];
    const processedValidations = processValidations(props.name, props.label, validations || {});
    return (
        <BaseForm.InputGroup className={classnames(showLabel ? "": "inline", props.className, props.disabled ? "disabled": "")}>
            {
                props.leftContent &&
                    <InputGroup.Text>{props.leftContent}</InputGroup.Text>
            }
            {
                showLabel &&
                    <Form.Label>{props.label}</Form.Label>
            }
            <BaseForm.Control {...pprops} />
            {
                props.rightContent &&
                    <InputGroup.Text>{props.rightContent}</InputGroup.Text>
            }
        </BaseForm.InputGroup>
    );
}
const ControlGroup = (props) => {
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    const rightContent = (props.type === "date" && !props.rightContent) ? <i className="fa fa-calendar"/> : props.rightContent;
    return (
        <BaseForm.InputGroup className={classnames(showLabel ? "": "inline", props.className)}
            leftContent={props.leftContent} rightContent={rightContent} disabled={props.disabled} readOnly={props.readOnly}>
            <BaseForm.Control {...props} />
        </BaseForm.InputGroup>
    );
}
BaseForm.Control = Control;
BaseForm.Control2 = Control2;
BaseForm.ControlGroup = ControlGroup;

const Text = (props) => {
    return (
        <BaseForm.Control {...props} type="text" />
    );
}
const TextGroup = (props) => {
    return (
        <BaseForm.ControlGroup {...props} type="text" />
    );
}
BaseForm.Text = Text;
BaseForm.TextGroup = TextGroup;

const Number = (props) => {
    return (
        <BaseForm.Control {...props} type="number" />
    );
}
const NumberGroup = (props) => {
    return (
        <BaseForm.ControlGroup {...props} type="number" />
    );
}
BaseForm.Number = Number;
BaseForm.NumberGroup = NumberGroup;

const Password = (props) => {
    return (
        <BaseForm.Control {...props} type="password" />
    );
}
const PasswordGroup = (props) => {
    return (
        <BaseForm.ControlGroup {...props} type="password" />
    );
}
BaseForm.Password = Password;
BaseForm.PasswordGroup = PasswordGroup;

const TextArea = (props) => {
    return (
        <BaseForm.Control {...props} type="textarea" />
    );
}
const TextAreaGroup = (props) => {
    return (
        <BaseForm.ControlGroup {...props} type="textarea" />
    );
}
BaseForm.TextArea = TextArea;
BaseForm.TextAreaGroup = TextAreaGroup;

const Date = (props) => {
    return (
        <BaseForm.Control {...props} type="date" />
    );
}
const DateGroup = (props) => {
    return (
        <BaseForm.ControlGroup {...props} type="date" />
    );
}
BaseForm.Date = Date;
BaseForm.DateGroup = DateGroup;

const Check = (props) => {
    const { initialFormFields, updateFormFields, registerName, control, processValidations, readOnly } = useContext(BaseFormContext);
    registerName(props.name);
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    const processedValidations = processValidations(props.name, props.validationLabel || props.label, props.validations);
    return (
        <>
        {
            showLabel &&
                <Form.Label className="inline">{props.label}</Form.Label>
        }
        <Controller
            control={control}
            name={props.name}
            rules={processedValidations}
            defaultValue={getValue(initialFormFields, props.name, false)}
            render={({ field }) => {
                return (
                    <Form.Check type="switch" className={props.className}
                        checked={getValue(initialFormFields, props.name, false)}
                        onChange={(event) => {
                            field.onChange(getValueFromEvent(event));
                            updateFormFields(props.name, getValueFromEvent(event));
                        }}
                        disabled={props.disabled}>
                        { props.children }
                    </Form.Check>
                );
            }}
        />
        </>
    );
}
const CheckGroup = (props) => {
    return (
        <BaseForm.InputGroup
            leftContent={props.leftContent} rightContent={props.rightContent} disabled={props.disabled}
            className={props.formClassName}
        >
            <BaseForm.Check {...props} />
        </BaseForm.InputGroup>
    );
}
BaseForm.Check = Check;
BaseForm.CheckGroup = CheckGroup;

const Color = (props) => {
    const { t } = useTranslation('common');
    const { initialFormFields, updateFormFields, registerName, control, processValidations } = useContext(BaseFormContext);
    registerName(props.name);
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    const [show, setShow] = useState(false);
    const style = {};
    const processedValidations = processValidations(props.name, props.label, props.validations);
    style["backgroundColor"] = (getValue(initialFormFields, props.name, null));

    const handleClick = useCallback((event) => {
        const length = $(event.target).closest('.form-color').length;
        if (length === 0) {
            setShow(false);
        }
    }, []);

    useEffect(() => {
        if (show) {
            document.addEventListener('click', handleClick);
        } else {
            document.removeEventListener('click', handleClick);
        }
    }, [show])

    return (
        <>
        {
            showLabel &&
                <BaseForm.Label className="inline">{props.label}</BaseForm.Label>
        }
        <Controller
            control={control}
            name={props.name}
            rules={processedValidations}
            defaultValue={getValue(initialFormFields, props.name, null)}
            render={({ field }) => {
                return (
                    <div className="form-color">
                        <div className="form-color-value" style={style} onClick={(event) => setShow(!show)} />
                        {
                            show &&
                                <GithubPicker
                                    triangle="hide"
                                    colors={getColorOptions(t).map((c) => c.value)}
                                    color={ getValue(initialFormFields, props.name, "")}
                                    onChange={(color) => {
                                        field.onChange(color.hex);
                                        updateFormFields(props.name, color.hex);
                                        setShow(false);
                                    }} />
                        }
                    </div>
                );
            }}
        />
        </>
    );
}
const ColorGroup = (props) => {
    return (
        <BaseForm.InputGroup leftContent={props.leftContent} rightContent={props.rightContent}
            disabled={props.disabled} className={props.className}>
            <BaseForm.Color {...props} />
        </BaseForm.InputGroup>
    );
}
BaseForm.Color = Color;
BaseForm.ColorGroup = ColorGroup;

const File = (props) => {
    const { initialFormFields, updateFormFields, registerName, registerFileField, control, processValidations } = useContext(BaseFormContext);
    registerName(props.name);
    if (!props.rawFile) {
        registerFileField(props.name, props.fileType);
    }
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    const value = getValue(initialFormFields, props.name);
    const processedValidations = processValidations(props.name, props.label, props.validations);

    const [fileValue, setFileValue] = useState(null);
    useEffect(() => {
        if (value !== undefined) {
            if (value.size) {
                var reader = new FileReader();
                reader.onload = function(event) {
                    setFileValue(event.target.result);
                };
                reader.readAsDataURL(value)
            } else {
                setFileValue(value);
            }
        } else {
            setFileValue(value);
        }
    }, [value])
    return (
        <>
            {
                showLabel &&
                    <BaseForm.Label>{props.label}</BaseForm.Label>
            }
            <Controller
                control={control}
                name={props.name}
                rules={processedValidations}
                defaultValue={getValue(initialFormFields, props.name, "")}
                render={({ field }) => {
                    return (
                        <>
                        <input 
                            type="file" name={props.name} label={props.label}
                            placeholder={props.placeholder}
                            accept={props.accept}
                            onChange={async (event) => {
                                field.onChange(getValueFromEvent(event));
                                updateFormFields(props.name, getValueFromEvent(event));
                            }}
                        />
                        {
                            !props.hidePreview && fileValue &&
                                <div className="file-clear">
                                    <Button variant="link" className="skinny"
                                        onClick={() => {
                                            field.onChange(null);
                                            updateFormFields(props.name, null);
                                        }}>
                                        <i className="fa fa-x"/>
                                    </Button>
                                </div>
                        }
                        </>
                    );
                }}
            />

            {
                !props.hidePreview && fileValue &&
                    <div className="file-preview"><img src={fileValue}/></div>
            }
        </>
    );
}
const FileGroup = (props) => {
    return (
        <BaseForm.InputGroup className="form-group" leftContent={props.leftContent} rightContent={props.rightContent}
            disabled={props.disabled}>
            <BaseForm.File {...props} />
        </BaseForm.InputGroup>
    );
}
BaseForm.File = File;
BaseForm.FileGroup = FileGroup;
FileGroup.propTypes = {
  fileType: PropTypes.string.isRequired
};

const EditorGroup = (props) => {
    const { initialFormFields, updateFormFields, registerName, control, processValidations } = useContext(BaseFormContext);
    registerName(props.name);

    const editor = useRef(null);
	const config = useMemo(() => {
	    return {
            readonly: false,
            placeholder: props.placeholder || "",
            height: props.height || 250,
            removeButtons: 'about',
            extraButtons: !_.isEmpty(props.tokens) ? [{
                name: 'Tokens',
                list: props.tokens,
                isParent: true,
                childTemplate: (editor, key, value) => {
                    return `<span>${key}</span>`;
                },
                exec: function (editor, t, {control}) {
                    if (!control.isParent) {
                        editor.selection.insertHTML(control.args[0]);
                    }
                }
            }] : []
	    };
	}, [props.tokens])
	const showLabel = !(props.hideLabel || _.isNil(props.label));
    const value = getValue(initialFormFields, props.name);
    const validations = props.validations || {};
    if (props.required) {
        validations['required'] = true;
    }
    const processedValidations = processValidations(props.name, props.label, validations);
    return (
        <BaseForm.InputGroup className="form-group" leftContent={props.leftContent} rightContent={props.rightContent}
            disabled={props.disabled}>
            {
                showLabel &&
                    <BaseForm.Label>{props.label}</BaseForm.Label>
            }
            <Controller
                control={control}
                name={props.name}
                rules={processedValidations}
                defaultValue={getValue(initialFormFields, props.name) || ""}
                render={({ field }) => {
                    return (
                        <JoditEditor
                            ref={editor}
                            value={value}
                            config={config}
                            tabIndex={1} // tabIndex of textarea
                            onBlur={newContent => {
                                field.onChange(newContent);
                                updateFormFields(props.name, newContent);
                            }}
                            onChange={newContent => newContent => {
                                field.onChange(newContent);
                                updateFormFields(props.name, newContent);
                            }}
                        />
                    );
                }}
            />
        </BaseForm.InputGroup>
    );
}
BaseForm.EditorGroup = EditorGroup;

const CheckboxRadio = (props) => {
    const { initialFormFields, updateFormFields, registerName, control, errors, processValidations } = useContext(BaseFormContext);
    registerName(props.name);
    const showLabel = !(props.hideLabel || _.isNil(props.label));
    const processedValidations = processValidations(props.name, props.visibleLabel, props.validations);
    const error = getValue(errors, props.name);
    const hasError = !_.isEmpty(error);

    return (
        <>
        {
            showLabel &&
                <Form.Label className="inline">{props.label}</Form.Label>
        }
        <Controller
            control={control}
            name={props.name}
            rules={processedValidations}
            defaultValue={getValue(initialFormFields, props.name)}
            render={({ field }) => {
                return (
                    <>
                    <Form.Check type={props.type || "radio"} className={classnames(props.className, hasError ? "error": "")}
                        value={props.value}
                        label={props.label}
                        checked={String(props.value) === String(getValue(initialFormFields, props.name))}
                        onChange={(event) => {
                            field.onChange(getValueFromEvent(event));
                            updateFormFields(props.name, getValueFromEvent(event));
                        }}
                        disabled={props.disabled}>
                        { props.children }
                    </Form.Check>
                    {
                        hasError &&
                            <p className="form-error-message text-start">{error.message || error}</p>
                    }
                    </>
                );
            }}
        />
        </>
    );
}
BaseForm.CheckboxRadio = CheckboxRadio;

const Divider = (props) => {
    return (
        <span className="input-group-text divider"></span>
    );
}
BaseForm.Divider = Divider;

const Label = (props) => {
    return (
        <Form.Label {...props}>
            { props.children }
        </Form.Label>
    );
}
BaseForm.Label = Label;

const Hidden = (props) => {
    const { initialFormFields, registerName, control } = useContext(BaseFormContext);
    registerName(props.name);
    return (
        <Controller
            control={control}
            name={props.name}
            defaultValue={getValue(initialFormFields, props.name)}
            render={({ field }) => {
                return (
                    <></>
                );
            }}
        />
    );
}
BaseForm.Hidden = Hidden;

export default BaseForm;
