import { useEffect, useState, useContext } from 'react';
import '../../App.scss';
import '../../css/forms.scss';
import BaseContainer from '../../components/Container';
import TabHeader from '../../components/TabHeader';
import BaseForm from '../../components/BaseForm';
import SubmitButton from '../../components/SubmitButton';
import { serverFetch, serverPatch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
import { useParams, useSearchParams } from "react-router-dom";
import { getTabItems } from '../../helpers/tabs'
import { Row, Col } from 'react-bootstrap';
import Notification from '../../components/Notification';
import { useTranslation } from 'react-i18next';
const _ = require("lodash");

function MultiGroup() {
    const { getApiUrl, getFacilityName } = useContext(BaseContext);
    let { facilityLink } = useParams();
    const [venueDetails, setVenueDetails] = useState({});
    const [ searchParams ] = useSearchParams();
    const venueId = searchParams.get('id');
    const { t } = useTranslation('common');

    useEffect(() => {
        document.title = `${venueDetails.name} (Multi Group) - ${getFacilityName()}`;
    }, [facilityLink, venueDetails]);

    useEffect(() => {
        serverFetch(getApiUrl(`/venues/${venueId}`)).then((res) => {
            setVenueDetails(res);
        });
    }, [venueId]);

    const updateSettings = (data) => {
        serverPatch(getApiUrl(`/venues/${venueId}/settings`), data, {}).then((res) => {
            if (res) {
                Notification.Show(t('notification.successfully_updated'));
            }
        });
    }

    const allowedGroupsOptions = _.range(4).map((r) => { return { value: ""+(r+1), label: ""+(r+1) } });

    const tabsParams = {
        searchParams: venueId && `id=${venueId}`,
        venueName: venueDetails.name
    };
    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "venue-settings", tabsParams)}/>
            <BaseForm initialFormFields={venueDetails} onSubmit={updateSettings}>
                <div className="content-box">
                    <div className="content-header">Multi Group Settings</div>
                    <div className="content-body">
                        <Row>
                        <Col md="7">
                        <Row>
                            <BaseForm.Input colSpan="6" name="noOfGroups" label="Allowed Per Booking" type="select"
                                options={allowedGroupsOptions} rightContent="Groups" showSearch={false} />
                            <BaseForm.Input colSpan="6" name="minGroups" label="Min Allowed Per Booking" type="select"
                                options={allowedGroupsOptions} rightContent="Groups" showSearch={false} />
                            <BaseForm.Input colSpan={12} name={'usersToSelectGroups'} label={"Allow Customers To Select Groups When Logged In"} type={'check'} />
                            <BaseForm.Input colSpan={12} name={'showCPC'} label={"Show Cost Per Customer (CPC)"} type={'check'} />
                            <BaseForm.Input colSpan={12} name={'CPCPaidUnpaid'} label={"CPC Paid/Unpaid"} type={'check'} />
                            <BaseForm.Input colSpan={12} name={'usersToAddGroups'} label={"Allow Users to Add New Groups"} type={'check'} />
                            <BaseForm.Input colSpan={12} name={'allowSplitBooking'} label={"Allow Split Bookings"} type={'check'} />
                            <BaseForm.Input colSpan={12} name={'mustBookPairs'} label={"User Must Book in Pairs"} type={'check'} />
                            <BaseForm.Input colSpan={12} name={'twoGroupsMax'} label={"Max 60 Min User Booking If Only 2 Groups"} type={'check'} />
                        </Row>
                        </Col>
                        </Row>
                    </div>
                </div>
                <div className="content-box">
                    <div className="content-body">
                        <SubmitButton variant="primary">Save</SubmitButton>
                    </div>
                </div>
            </BaseForm>
        </BaseContainer>
    );
}

export default MultiGroup;
