import '../../App.scss';
import '../../css/modals.scss';
import { useState, useEffect, useContext } from 'react';
import BaseModal from './BaseModal';
import BaseForm from '../BaseForm';
import SubmitButton from '../SubmitButton';
import { Button, Row, Col } from 'react-bootstrap';
import { serverPost, serverPatch } from '../../helpers/server';
import { BaseContext } from '../../helpers/common';
const _ = require("lodash");

function BaseAddOrEditItemModal(props) {
    const { getApiUrl } = useContext(BaseContext);
    const [isEditing, setIsEditing] = useState(false);

    const addItem = (itemFields) => {
        const addFields = props.processAddFields({...itemFields});
        if (props.addItem) {
            props.addItem(addFields);
        } else {
            const addItemUrl = getApiUrl(props.itemBaseUrl);
            serverPost(addItemUrl, addFields).then((res) => {
                props.onClose();
            })
        }
    }

    const updateItem = (itemFields) => {
        const updateFields = props.processUpdateFields({...itemFields})
        if (props.updateItem) {
            props.updateItem(updateFields);
        } else {
            const updateItemUrl = getApiUrl(`${props.itemBaseUrl}/${props.itemToEdit.id}`);
            const data = {...updateFields};
            serverPatch(updateItemUrl, data).then((res) => {
                props.onClose();
            })
        }
    }

    useEffect(() => {
         setIsEditing(!_.isNil(props.itemToEdit));
    }, [props.itemToEdit]);

    return (
        <BaseModal {...props}>
            <BaseModal.Header>
                <BaseModal.Title>{isEditing ? `Edit ${props.itemLabel}` : `Add ${props.itemLabel}`}</BaseModal.Title>
            </BaseModal.Header>
            <BaseForm initialFormFields={props.itemToEdit || props.initialFormFields} onFieldChange={props.onFieldChange} onSubmit={isEditing ? updateItem : addItem}
                errorFields={props.errorFields}>
                <BaseModal.Body>
                    { props.children }
                </BaseModal.Body>
                <BaseModal.Footer>
                    <Row>
                        <Col md="12" className="text-end">
                            <SubmitButton variant="primary">{isEditing ? `Update ${props.itemLabel}` : `Add ${props.itemLabel}`}</SubmitButton>
                        </Col>
                    </Row>
                </BaseModal.Footer>
            </BaseForm>
        </BaseModal>
    );

}

export default BaseAddOrEditItemModal;

BaseAddOrEditItemModal.defaultProps = {
    processAddFields: (fields) => fields,
    processUpdateFields: (fields) => fields
};
