import '../../App.scss';
import '../../css/modals.scss';
import { useState, useEffect } from 'react';
import BaseAddOrEditItemModal from './BaseAddOrEditItemModal';
import { useTranslation } from 'react-i18next';
import BaseForm from '../BaseForm';
import { Row } from 'react-bootstrap';

function AddOrEditClassFormModal(props) {
    const { t } = useTranslation(['common']);

    return (
        <BaseAddOrEditItemModal
            {...props}
            itemLabel={"Class Form"}
            itemBaseUrl={'/forms'}
        >
            <Row>
                <BaseForm.Input colSpan={12} name={'name'} label={t('forms.form_name')} type={'text'} required />
            </Row>
        </BaseAddOrEditItemModal>
    );

}

export default AddOrEditClassFormModal;
